import {
  Box,
  Card,
  CardContent,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import {AxiosError} from 'axios'
import {get, isEmpty, pick} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from 'react-query'
import {useFeaturesState} from '../../../Organisms/Features'
import {usePersistContact} from '../../../common/react-query/hooks/mutations/usePersistContact'
import {usePersistOrderRequests} from '../../../common/react-query/hooks/mutations/usePersistOrderRequests'
import {BusinessLineType} from '../../../common/types'
import {useOrderIntakeStyles} from '../../../Hooks/OrderIntake/useOrderIntakeStyles'
import {ConfirmationScreen} from '../../../OrderIntake/components/ConfirmationScreen'
import {ShippingType} from '../../../OrderIntake/declarations/OrderIntake.enums'
import {useBulkCementOrderIntake} from '../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {BulkOrderFormValues} from '../../BulkOrdersFormValues'
import {CollectionProcessingOrderCard} from '../../components/Collect/CollectionProcessingCard'
import {
  GroupedPlacedDeliveries,
  OrderIntakePayload,
  OrderRequestGroupResponse,
  PersistedOrderRequest,
  QuantityType
} from '../../declarations/types'
import {OptionsKeys} from '../../Options'
import {groupPlacedDeliveries} from '../../../Organisms/OrderIntake/utils'
import {QuantityUomToSymbolComponent} from '../../../../src/Pages/Order/Order.components'

interface HubErrorResponseData {
  detail: string
  status: string
  title: string
}

interface Props {
  businessLine: BusinessLineType
  quantityType: QuantityType
  quantityUom: string
  groupOrderSummaryDataArray: OrderRequestGroupResponse[]
  isLoading: boolean
  isError: boolean
  onClose: () => void
}

export const CollectOrderIntakeRequestConfirmation: React.FC<Props> = ({
  onClose,
  businessLine,
  quantityUom,
  quantityType,
  groupOrderSummaryDataArray,
  isLoading,
  isError
}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useOrderIntakeStyles()
  const queryClient = useQueryClient()
  const optionsReloaded = useRef(false)
  const {getFeature} = useFeaturesState()
  const [customErrorMessage, setCustomErrorMessage] = useState<string | null>('')
  const {collections, orderSummary} = useBulkCementOrderIntake()
  const {watch} = useFormContext<BulkOrderFormValues>()
  const selectedSite = watch('selectedSite')

  const mainPhone = get(collections[0], 'payload.contact.mainPhone', '')
  const backupPhone = get(collections[0], 'payload.contact.backupPhone', '')
  const userFullName = get(collections[0], 'payload.contact.fullName', '')
  const [showError, setShowError] = useState<boolean>(false)
  const siteNumber = watch('selectedSite.shippingAddress.siteNumber')
  const contact = watch('contact')
  const {mutate: persistContact} = usePersistContact()
  const {mutate: persistOrderRequests} = usePersistOrderRequests()

  const [groupedPlacedDeliveries, setGroupedPlacedCollections] = useState<
    GroupedPlacedDeliveries[]
  >([])

  useEffect(() => {
    if (isEmpty(collections)) return
    setGroupedPlacedCollections(
      groupPlacedDeliveries(collections, selectedSite, groupOrderSummaryDataArray)
    )
    persistContact({siteNumber, contact})
    persistOrderRequests({
      siteNumber,
      orderRequests: [
        ...collections.map((collection) => ({
          ...(pick(collection.payload, [
            'materialNumber',
            'materialEnteredNumber',
            'materialDescription',
            'capacity',
            'deliveryTime',
            'customerReference',
            'contractItemPositionNumber',
            'isSendingConfirmationEmailUnChecked',
            'plantName'
          ]) as PersistedOrderRequest)
        }))
      ]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections, groupOrderSummaryDataArray])

  const onItemError = (error: AxiosError) => {
    setShowError(true)

    // 'One or more errors occurred while creating the order, possible reasons: InvalidSlot'
    if (error.response?.data) {
      const errorData = error.response.data as HubErrorResponseData
      if (errorData.detail && errorData.detail.indexOf('Provided slot') > -1) {
        setCustomErrorMessage('invalidSlot')
        if (!optionsReloaded.current) {
          void queryClient.invalidateQueries(OptionsKeys.all())
          optionsReloaded.current = true
        }
      } else {
        setCustomErrorMessage(null)
      }
    }
  }

  const renderPlacedDeliveryItem = (
    placedCollection: OrderIntakePayload,
    showLoad: boolean,
    showTime: boolean,
    index: number,
    groupedOrderSummary: OrderRequestGroupResponse | undefined
  ) => {
    return (
      <CollectionProcessingOrderCard
        isMobile={isMobile}
        showLoad={showLoad}
        showTime={showTime}
        businessLine={businessLine}
        quantityUom={quantityUom}
        collection={placedCollection}
        itemOrder={`${t('orderIntake.delivery')} ${index + 1}`}
        onError={onItemError}
        customErrorMessage={
          customErrorMessage ? t(`orderIntake.customErrorMessage.${customErrorMessage}`) : undefined
        }
        groupedOrderSummary={groupedOrderSummary}
        isLoading={isLoading}
        isError={isError}
      />
    )
  }

  return (
    <ConfirmationScreen
      data-test-id="collect-order-intake-confirmation-screen"
      deliveryAddress={watch('selectedSite.shippingAddress')}
      showError={showError}
      summaryItems={groupedPlacedDeliveries.map((group, index) => {
        return (
          <Card
            variant="outlined"
            className={classes.confirmationSummaryItem}
            key={`Collect-Item-Order-Confirmation-Processing-${index}`}
          >
            <CardContent>
              <Box className={classes.confirmationMaterialHeader}>
                <Typography variant="caption">
                  {t('orderIntake.confirmationScreen.materialLoad')}
                </Typography>
                <Typography variant="h4" style={{marginTop: 8}}>
                  {`${group.headline.quantity}`}
                  {quantityType === QuantityType.Load ? (
                    ` ${t('orderIntake.loads', {count: group.headline.quantity})} `
                  ) : (
                    <QuantityUomToSymbolComponent uom={group.headline.capacityUom} />
                  )}
                  {` ${group.headline.description}`}
                </Typography>
              </Box>

              <Table>
                {!isMobile && (
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      {group.orders.length > 1 && (
                        <TableCell>{t('orderIntake.materialOrder.load')}</TableCell>
                      )}
                      <TableCell>{t('orderIntake.materialOrder.date')}</TableCell>
                      <TableCell></TableCell>
                      <TableCell>{t('orderIntake.confirmationScreen.additionalInfo')}</TableCell>
                      <TableCell>{t('orderIntake.customerReference')}</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                {group.orders.map((order: OrderIntakePayload, index: number) =>
                  renderPlacedDeliveryItem(
                    order,
                    group.orders.length > 1,
                    getFeature('OrderIntakeCollectTimePicker'),
                    index,
                    group.groupedOrderSummary[index]
                  )
                )}
              </Table>
            </CardContent>
          </Card>
        )
      })}
      date={orderSummary[0]?.createdOn}
      onClose={onClose}
      mainPhone={mainPhone}
      backupPhone={backupPhone}
      userFullName={userFullName}
      shippingType={ShippingType.COLLECT}
      itemsNumber={collections.length}
    />
  )
}
