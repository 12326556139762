/* eslint-disable complexity */
import {Modal, Typography, SectionHeader, useNotification} from '@hconnect/uikit'
import {Card} from '@material-ui/core'
import {Paper, Box, Grid, Hidden, Stack, Dialog, DialogContent} from '@mui/material'
import parsePhoneNumberFromString, {CountryCode} from 'libphonenumber-js'
import {isEmpty, isEqual, uniq, first, values, partial, partialRight} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useForm, useFieldArray, FormProvider, Controller, SubmitHandler} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import distributedCacheLoadingImage from '../../../Assets/distributedCacheLoading.svg'
import noValidMaterialImage from '../../../Assets/noValidMaterials/no-delivery-placeholder.svg'
import {trackEvent} from '../../../common/analytics'
import {usePersistedContact} from '../../../common/react-query/hooks/queries/usePersistedContact'
import {usePersistedOrderRequests} from '../../../common/react-query/hooks/queries/usePersistedOrderRequests'
import {invalidateHubQuery} from '../../../common/react-query/QueryProvider'
import {useOrderIntakeStyles} from '../../../Hooks/OrderIntake/useOrderIntakeStyles'
import {SiteDropdown} from '../../../OrderIntake/components/SiteDropdown'
import {useFeaturesState} from '../../../Organisms/Features'
import {useBulkCementOrderIntake} from '../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {
  checkEmptyTimeZone,
  generateGuid,
  getCurrentTimeStamp,
  getDefaultMaterialOptions
} from '../../../Organisms/OrderIntake/utils'
import {BulkOrderFormValues} from '../../BulkOrdersFormValues'
import {AddAnotherLegacyButton} from '../../components/AddAnotherLegacyButton'
import {
  PersistedMaterialsAction,
  PersistedMaterialsBanner
} from '../../components/PersistedMaterialsDialog/PersistedMaterialsBanner'
import {PersistedMaterialsDialog} from '../../components/PersistedMaterialsDialog/PersistedMaterialsDialog'
import {ShowOrderSummaryLegacySection} from '../../components/ShowOrderSummaryLegacyBtn'
import {SummaryNoValidMaterials} from '../../components/SummaryNoValidMaterials'
import {isTwelveHoursFormat} from '../../components/TimeScroller/TimeScroller.utils'
import {DEFAULT_CUSTOMER_REFERENCE} from '../../declarations/constants'
import {ShippingType} from '../../declarations/OrderIntake.enums'
import {
  OrderRequest,
  HaulerInfo,
  OrderIntakeMaterialOptionPayload,
  PersistedOrderRequest
} from '../../declarations/types'
import {useBulkOrderFormChangeHandlerWithMethods} from '../../FormChangeHandlers'
import {useOrderIntakeData} from '../../hooks'
import {useActivePopup} from '../../hooks/useActivePopup'
import * as options from '../../Options'
import {isInvalidOrderIntakeOption} from '../../Options/query'
import {ContactLegacySection} from '../containers/ContactLegacySection'
import {MaterialOrderLegacyEditor} from '../containers/MaterialOrderEditor/MaterialOrderLegacyEditor'
import {OrderSummaryLegacySection} from '../containers/OrderSummaryLegacySection'
import {useHandleClose} from '../hooks/useHandleClose'
import {useMaterialEditorController} from '../hooks/useMaterialEditorController'
import {
  areAllCustomerReferencesSame,
  getMaterialOptionsByInvalidity,
  isMaterialInvalidBySlots,
  mergePersistedContact,
  mergePersistedOrders
} from '../utils'

import {CollectMaterialLegacyForm} from './CollectMaterialLegacyForm'
import {CollectOrderIntakeRequestLegacyConfirmation} from './CollectOrderIntakeRequestLegacyConfirmation'

export const BulkCementCollectOrderLegacyForm: React.FC = () => {
  const classes = useOrderIntakeStyles()
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {
    customerId,
    userId,
    userPrimaryOrganisationalUnit,
    userFullName,
    userProfileCountry,
    features
  } = useOrderIntakeData()
  const {getFeature} = useFeaturesState()
  const {notify} = useNotification()

  const isSlotsManagementEnabled = getFeature('OrderIntakeSlotsCollect')
  const nextDayCollect = getFeature('OrderIntakeChangeOrderDefaultDayCollect')
  const applyMaterialEnteredDescription = getFeature('OrderIntakeMaterialEnteredDescription')
  const applyContractItemDescription = getFeature('OrderIntakeContractItemDescription')
  const {setCollections, slotConfiguration} = useBulkCementOrderIntake()

  const configuration = {
    isSlotsManagementEnabled,
    nextDayCollect,
    applyMaterialEnteredDescription,
    applyContractItemDescription
  }

  const {data: collectOptions} = options.useCollectionOptions(configuration, customerId)
  const {data: defaultCollection} = options.useDefaultCollection(customerId, configuration)
  const defaultBusinessLine = options.getBusinessLine(collectOptions)

  const siteOptions = values(collectOptions)
  const methods = useForm<BulkOrderFormValues>({
    mode: 'all',
    defaultValues: {
      selectedSite: siteOptions[0],
      orders: [],
      contact: {fullName: undefined, mainPhone: undefined},
      isConfirmationMailChecked: true,
      isOrderRequestConfirmed: false
    }
  })

  const {
    fields: orderFields,
    append: appendOrder,
    remove: deleteOrder,
    replace: replaceOrders
  } = useFieldArray({
    control: methods.control,
    name: 'orders'
  })

  const {selectedSite, orders} = methods.watch()

  const siteNumber = methods.watch('selectedSite.shippingAddress.siteNumber')
  const {data: persistedContact} = usePersistedContact(siteNumber)
  const {data: persistedOrderRequests, isFetching: isOrderRequestsCacheLoading} =
    usePersistedOrderRequests(siteNumber)

  useEffect(() => {
    setOrderSessionEventId(generateGuid())
  }, [])

  useEffect(() => {
    if (
      !collectOptions ||
      !defaultCollection ||
      !userFullName ||
      isInvalidOrderIntakeOption(defaultCollection)
    )
      return

    const orderRequestBySiteNumber = orders.find((or) => or.payload.siteNumber === siteNumber)
    const orderRequests = orderRequestBySiteNumber
      ? [orderRequestBySiteNumber]
      : [defaultCollection]

    const contact = mergePersistedContact(
      selectedSite,
      persistedContact,
      features.isContactPersistency
    )

    methods.setValue(
      'isConfirmationMailChecked',
      !first(persistedOrderRequests)?.isSendingConfirmationEmailUnChecked ?? true
    )

    methods.setValue(
      'isBackupPhoneVisible',
      features.hasSiteContactNameAndPhone &&
        !isEmpty(contact?.backupPhone) &&
        contact?.backupPhone !== contact?.mainPhone
    )

    formChangeHandlers.onSiteChange(siteNumber, collectOptions, defaultCollection)

    replaceOrders(
      orderRequests.map((orderRequest) => ({
        ...orderRequest,
        payload: {
          ...orderRequest.payload,
          customerReference: orderRequest.payload.customerReference || DEFAULT_CUSTOMER_REFERENCE
        },
        initialFullName: userFullName
      }))
    )

    methods.reset({
      ...methods.getValues(),
      isOrderRequestConfirmed: false,
      contact: {
        mainPhone: contact.mainPhone
          ? (parsePhoneNumberFromString(contact.mainPhone, userProfileCountry as CountryCode)
              ?.number as string)
          : '',
        backupPhone: contact.backupPhone
          ? (parsePhoneNumberFromString(contact.backupPhone, userProfileCountry as CountryCode)
              ?.number as string)
          : '',
        fullName: contact.fullName ? contact.fullName : userFullName,

        carbonCopyEmail: contact.carbonCopyEmail,
        siteContactName: features.hasSiteContactNameAndPhone ? contact.siteContactName : ''
      }
    })

    getValidMaterialsForPersistency()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    siteNumber,
    collectOptions,
    defaultCollection,
    userFullName,
    persistedContact,
    persistedOrderRequests
  ])

  const [showConfirmationStatusScreen, setShowConfirmationStatusScreen] = useState<boolean>(false)
  const [editorActivationTimeStamp, setEditorActivationTimeStamp] = useState<string>()
  const [orderSessionEventId, setOrderSessionEventId] = useState<string>()
  const [showPersistedMaterialsBanner, setShowPersistedMaterialsBanner] = useState<boolean>(true)
  const [showPersistedMaterialsDialog, setShowPersistedMaterialsDialog] = useState<boolean>(false)
  const [validMaterialsForPersistency, setValidMaterialsForPersistency] = useState<
    OrderIntakeMaterialOptionPayload[]
  >([])
  const [existsValidPersistedMaterials, setExistsValidPersistedMaterials] = useState<boolean>(false)

  useEffect(() => {
    checkValidPersistedMaterials()
  }, [validMaterialsForPersistency, persistedOrderRequests])

  const getValidMaterialsForPersistency = () => {
    const materialOptions = Object.values(selectedSite.materials).flatMap((m) => m)
    const validMaterialOptions: OrderIntakeMaterialOptionPayload[] = []
    const checkValidityBySlots = isSlotsManagementEnabled

    const deliveryDate =
      orders.length === 0
        ? (defaultCollection as OrderRequest)?.payload?.deliveryDate
        : orders[0].payload.deliveryDate

    materialOptions.forEach((material) => {
      const invalidBySlots =
        checkValidityBySlots &&
        slotConfiguration &&
        isMaterialInvalidBySlots(material, deliveryDate, slotConfiguration)
      if (!material.invalid && !invalidBySlots) {
        validMaterialOptions.push(material)
      }
    })

    setValidMaterialsForPersistency(validMaterialOptions)
  }

  const checkValidPersistedMaterials = () => {
    let existsValidMaterial = false
    persistedOrderRequests?.forEach((material) => {
      if (isMaterialValid(material)) existsValidMaterial = true
    })

    setExistsValidPersistedMaterials(existsValidMaterial)
  }

  const isMaterialValid = (material: PersistedOrderRequest) => {
    return validMaterialsForPersistency.some(
      (item) => item.material.materialEnteredNumber === material.materialEnteredNumber
    )
  }

  const handleClose = useHandleClose(
    orders,
    setShowConfirmationStatusScreen,
    methods.reset,
    customerId
  )

  const materialEditorController = useMaterialEditorController()
  const formChangeHandlers = useBulkOrderFormChangeHandlerWithMethods(methods)
  const [activePopup, setActivePopup] = useActivePopup(
    materialEditorController.setActiveOrderBox,
    materialEditorController.activeMaterialForm
  )

  if (
    !collectOptions ||
    !selectedSite ||
    !defaultCollection ||
    isInvalidOrderIntakeOption(defaultCollection)
  )
    return null

  const addCollection = () => {
    const lastOrderItem = orders[orders.length - 1]
    appendOrder({...lastOrderItem, payload: {...lastOrderItem.payload, haulerInfo: {}}})
    trackEvent('hubOrderIntakeAddOrderRequest')
  }

  const removeCollection = (index: number) => {
    deleteOrder(index)
    trackEvent('hubOrderIntakeRemoveOrderRequest')
  }

  const onSubmit: SubmitHandler<BulkOrderFormValues> = (data) => {
    if (materialEditorController.activeMaterialForm) return

    const updatedCollections = data.orders.map((collection) => ({
      ...collection,
      payload: {
        ...collection.payload,
        isSendingConfirmationEmailUnChecked: !data.isConfirmationMailChecked,
        contact: {
          ...data.contact,
          siteContactName:
            isEmpty(data.contact?.siteContactName) && features.hasSiteContactNameAndPhone
              ? data.contact?.fullName
              : data.contact?.siteContactName,
          backupPhone:
            isEmpty(data.contact?.backupPhone) && features.hasSiteContactNameAndPhone
              ? data.contact?.mainPhone
              : data.contact?.backupPhone
        }
      }
    }))

    methods.setValue('isOrderRequestConfirmed', false)

    setShowConfirmationStatusScreen(true)
    setCollections(updatedCollections as OrderRequest[])

    updatedCollections.map((collection) => {
      if (collection.payload.deliveryDate !== collection.initialDeliveryDate) {
        trackEvent('hubOrderIntakeDefaultDateNotUsed')
      }
      if (!isEqual(collection.payload.deliveryTime, collection.initialDeliveryTime)) {
        trackEvent('hubOrderIntakeDefaultTimeNotUsed')
      }
      if (collection.payload.capacity.quantity !== collection.initialTruckQuantity) {
        trackEvent('hubOrderIntakeDefaultQuantityNotUsed')
      }
      if (!isEqual(collection.payload.contact?.fullName, collection.initialFullName)) {
        trackEvent('hubOrderIntakeOrderPlacerNameChanged')
      }
    })
    trackEvent('hubOrderIntakeEnd', {
      shippingType: ShippingType.COLLECT,
      numberOfMaterials: uniq(
        updatedCollections.map((collection) => collection.payload.materialEnteredNumber)
      ).length,
      businessLine: defaultBusinessLine,
      quantityType: defaultCollection.payload.capacity.quantityType,
      orderVolume: updatedCollections.reduce((result, collection) => {
        return {
          ...result,
          [collection.payload.materialEnteredNumber]:
            (result[collection.payload.materialEnteredNumber] || 0) +
            collection.payload.capacity.quantity
        }
      }, {}),
      numberOfOrderRequests: updatedCollections.length,
      purchaseOrderNumberVisible: features.orderIntakeCustomerReferenceField
    })

    const isTwelveHours = isTwelveHoursFormat(language)

    orders.map((delivery) => {
      if (delivery.timeTrackingData) {
        delivery.timeTrackingData.map((userInteraction) => {
          trackEvent('hubOrderIntakeEditTime', {
            orderSessionEventId,
            cardId: userInteraction.cardId,
            slotManaged: userInteraction.slotManaged,
            startEditingTimeStamp: userInteraction.startEditingTimeStamp,
            applyChangesTimeStamp: userInteraction.applyChangesTimeStamp,
            events: userInteraction.events,
            earliestTimeFormat: isTwelveHours ? '12h' : '24h',
            latestTimeFormat: isTwelveHours ? '12h' : '24h'
          })
        })
      }
    })
  }

  const onHaulerInfoChange = (index: number, haulerInfo?: HaulerInfo) => {
    methods.setValue(`orders.${index}.payload.haulerInfo`, haulerInfo)
  }

  const onCustomerReferenceChange = (index: number, customerReference: string) => {
    methods.setValue(`orders.${index}.payload.customerReference`, customerReference)
  }

  const onEditorActivation = (index: number) => {
    const orderPath = `orders.${index}` as const
    const order = methods.getValues(orderPath)

    const defaultMaterialOption = first(selectedSite.materials[order.payload.materialEnteredNumber])
    const timeZone = defaultMaterialOption?.businessHours.timeZone
    setEditorActivationTimeStamp(getCurrentTimeStamp(checkEmptyTimeZone(timeZone)))
  }

  const onClosePersistedMaterialsBanner = (action: PersistedMaterialsAction) => {
    switch (action) {
      case PersistedMaterialsAction.ApplyMaterials: {
        applyPersistedMaterials()
        trackPersistedMaterialsShortcutButton()
        notify('info', t('orderIntake.materialPersistency.confirmationMessage'), {
          autoHideDuration: 2000
        })
        return
      }
      case PersistedMaterialsAction.ShowMaterials: {
        setShowPersistedMaterialsDialog(true)
        return
      }
      default: {
        setShowPersistedMaterialsBanner(false)
      }
    }
  }

  const onClosePersistedMaterials = (apply: boolean) => {
    setShowPersistedMaterialsDialog(false)

    if (apply) {
      applyPersistedMaterials()
    }
  }

  const applyPersistedMaterials = () => {
    const orderRequestBySiteNumber = orders.find((or) => or.payload.siteNumber === siteNumber)

    const validPersistedOrderRequests = persistedOrderRequests?.filter((persistedItem) =>
      validMaterialsForPersistency.some(
        (item) => item.material.materialEnteredNumber === persistedItem.materialEnteredNumber
      )
    )

    const orderRequests = mergePersistedOrders(
      orderRequestBySiteNumber ? orderRequestBySiteNumber : defaultCollection,
      selectedSite,
      validPersistedOrderRequests
    )

    if (orderRequests) {
      replaceOrders(
        orderRequests.map((orderRequest) => ({
          ...orderRequest,
          payload: {
            ...orderRequest.payload,
            customerReference: orderRequest.payload.customerReference || DEFAULT_CUSTOMER_REFERENCE
          }
        }))
      )
    }

    trackEvent('hubOrderIntakeReviewPersistedMaterials', {
      user_uuid: userId,
      country: userProfileCountry,
      primaryOrganisationalUnit: userPrimaryOrganisationalUnit,
      customerId: customerId,
      siteId: siteNumber,
      shippingType: ShippingType.COLLECT,
      businessLine: defaultBusinessLine
    })
  }

  const trackPersistedMaterialsShortcutButton = () => {
    trackEvent('hubOrderIntakeShortcutCopyPersistedMaterials', {
      user_uuid: userId,
      country: userProfileCountry,
      primaryOrganisationalUnit: userPrimaryOrganisationalUnit,
      customerId: customerId,
      siteId: siteNumber,
      shippingType: ShippingType.COLLECT,
      businessLine: defaultBusinessLine
    })
  }

  const isCustomerReferenceSame = areAllCustomerReferencesSame(orders)
  const validMaterialOptions = getMaterialOptionsByInvalidity(selectedSite.materials, false)
  const invalidMaterialOptions = getMaterialOptionsByInvalidity(selectedSite.materials, true)
  const noValidMaterial = invalidMaterialOptions.length >= 1 && isEmpty(validMaterialOptions)
  const selectedMaterialIndex = orderFields.findIndex(
    (d) => d.id === materialEditorController.activeMaterialForm
  )

  // we cannot have form in form, if so, it won't work and submit button will trigger upper form
  // so when there is activeMaterialForm, we disable the form and render only div
  const isMainFormActive = !materialEditorController.activeMaterialForm
  const Form = isMainFormActive ? 'form' : 'div'
  return (
    <FormProvider {...methods}>
      <Form onSubmit={isMainFormActive ? methods.handleSubmit(onSubmit) : undefined}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={4}>
              <Grid container justifyContent="space-between">
                {showConfirmationStatusScreen ? (
                  <Modal open={true} onClose={handleClose}>
                    <CollectOrderIntakeRequestLegacyConfirmation
                      businessLine={defaultBusinessLine}
                      onClose={handleClose}
                    />
                  </Modal>
                ) : null}

                {features.isMaterialPersistency &&
                  persistedOrderRequests &&
                  showPersistedMaterialsDialog && (
                    <Dialog open={true} onClose={onClosePersistedMaterials}>
                      <DialogContent>
                        <PersistedMaterialsDialog
                          persistedMaterials={persistedOrderRequests}
                          validMaterials={validMaterialsForPersistency}
                          existsValidMaterials={existsValidPersistedMaterials}
                          onClose={onClosePersistedMaterials}
                        />
                      </DialogContent>
                    </Dialog>
                  )}

                <Grid
                  container
                  className={classes.container}
                  justifyContent="space-between"
                  data-test-id="order-intake-form-container"
                >
                  <Grid container spacing={4}>
                    <Hidden mdDown={!!activePopup}>
                      <Grid item xs={12} md={8}>
                        <SectionHeader
                          data-test-id="collection-site-selection-section-header"
                          title={t('orderIntake.deliveryAddress')}
                          caption={t('orderIntake.lastDestinationShown')}
                          first
                        />
                        <Grid item xs={12} md={8}>
                          <Controller
                            control={methods.control}
                            name="selectedSite"
                            render={({field}) => (
                              <SiteDropdown
                                onChange={partialRight(
                                  formChangeHandlers.onSiteChange,
                                  collectOptions,
                                  defaultCollection
                                )}
                                shippingType={ShippingType.COLLECT}
                                disabled={!isMainFormActive}
                                selectedSite={field.value}
                                options={siteOptions}
                                fetchOrderIntakeOptions={() =>
                                  invalidateHubQuery('orderRequestsOptions', {
                                    customerId,
                                    applyMaterialEnteredDescription,
                                    applyContractItemDescription
                                  })
                                }
                              />
                            )}
                          />
                        </Grid>

                        {features.isMaterialPersistency &&
                          persistedOrderRequests &&
                          showPersistedMaterialsBanner &&
                          !noValidMaterial && (
                            <PersistedMaterialsBanner
                              onClose={onClosePersistedMaterialsBanner}
                              existsValidMaterials={existsValidPersistedMaterials}
                            />
                          )}

                        {!noValidMaterial ? (
                          isOrderRequestsCacheLoading ? (
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              marginTop={10}
                            >
                              <img
                                src={distributedCacheLoadingImage}
                                data-test-id="order-intake-loading-data-from-cache"
                                style={{textAlign: 'center', maxWidth: 350}}
                                alt={t('orderIntake.loadingDataFromCache')}
                              />
                              <Box marginTop={2}>
                                <Typography
                                  variant="body2"
                                  color="secondary"
                                  style={{textAlign: 'center', maxWidth: 350}}
                                >
                                  {t('orderIntake.loadingDataFromCache')}
                                </Typography>
                              </Box>
                            </Box>
                          ) : (
                            <>
                              <Stack spacing={2}>
                                <SectionHeader
                                  data-test-id="collection-material-selection-section-header"
                                  title={t('orderIntake.cementTypeDelivery')}
                                  caption={t('orderIntake.startSelectionFromLastOrder')}
                                />
                                {orderFields.map((collection, index) => {
                                  const defaultMaterialOption = getDefaultMaterialOptions(
                                    selectedSite.materials,
                                    collection.payload
                                  )
                                  const {status, isSuccess} =
                                    materialEditorController.getMaterialFormProps(collection.id)
                                  if (!defaultMaterialOption) return null
                                  return (
                                    <CollectMaterialLegacyForm
                                      key={collection.id}
                                      defaultMaterialOption={defaultMaterialOption}
                                      isCustomerReferenceSame={isCustomerReferenceSame}
                                      orderIndex={index}
                                      removeCollection={() => removeCollection(index)}
                                      isSuccess={isSuccess}
                                      status={status}
                                      onActivate={(activeItem) => {
                                        materialEditorController.setActiveOrderBox(
                                          collection.id,
                                          activeItem
                                        )
                                        onEditorActivation(index)
                                      }}
                                    />
                                  )
                                })}
                                <AddAnotherLegacyButton
                                  businessLine={defaultBusinessLine}
                                  shippingType={ShippingType.COLLECT}
                                  disabled={
                                    materialEditorController.activeMaterialForm !== undefined ||
                                    isOrderRequestsCacheLoading
                                  }
                                  onClick={addCollection}
                                />
                              </Stack>
                              <ContactLegacySection
                                shippingType={ShippingType.COLLECT}
                                dataTestPrefix="collection"
                              />
                            </>
                          )
                        ) : null}
                        {noValidMaterial && (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            marginTop={10}
                          >
                            <img
                              src={noValidMaterialImage}
                              data-test-id="order-intake-no-valid-ship-to-image"
                              style={{textAlign: 'center', maxWidth: 350}}
                              alt={t(
                                'orderIntake.invalidMaterials.deliveryNoPossibleForDestination'
                              )}
                            />
                            <Box marginTop={2}>
                              <Typography
                                variant="body2"
                                color="secondary"
                                style={{textAlign: 'center', maxWidth: 350}}
                              >
                                {t(
                                  'orderIntake.invalidMaterials.deliveryNoPossibleForDestinationMessage'
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Grid>
                    </Hidden>
                    <Hidden mdDown={!activePopup}>
                      <Grid item xs={12} md={4}>
                        <div className={classes.stickyContainer}>
                          {materialEditorController.activeMaterialForm === undefined ? (
                            <OrderSummaryLegacySection
                              shippingType={ShippingType.COLLECT}
                              customerId={customerId}
                              quantityType={defaultCollection.payload.capacity.quantityType}
                              onCloseSummaryBox={() => setActivePopup(undefined)}
                              onCustomerRefChange={formChangeHandlers.onGlobalCustomerRefChange}
                              onEmailCcChange={formChangeHandlers.onGlobalEmailCcChange}
                            />
                          ) : (
                            <MaterialOrderLegacyEditor
                              key={materialEditorController.activeMaterialForm}
                              index={selectedMaterialIndex}
                              shippingType={ShippingType.COLLECT}
                              activeItem={materialEditorController.activeSection}
                              showPoTab={!isCustomerReferenceSame}
                              closeActiveItem={() =>
                                materialEditorController.setActiveOrderBox(undefined)
                              }
                              onChange={() =>
                                materialEditorController.activeMaterialForm &&
                                materialEditorController.notifySuccessfullySaved(
                                  materialEditorController.activeMaterialForm
                                )
                              }
                              onDateChange={partial(
                                formChangeHandlers.onDateChange,
                                selectedMaterialIndex
                              )}
                              onTimeChange={partial(
                                formChangeHandlers.onTimeChange,
                                selectedMaterialIndex
                              )}
                              onHaulerInfoChange={(haulerInfo) =>
                                onHaulerInfoChange(selectedMaterialIndex, haulerInfo)
                              }
                              onCustomerReferenceChange={(customerReference) =>
                                onCustomerReferenceChange(selectedMaterialIndex, customerReference)
                              }
                              onTimeSelectorTrack={partial(
                                formChangeHandlers.onTimeSelectorTrackApply,
                                selectedMaterialIndex,
                                editorActivationTimeStamp,
                                isSlotsManagementEnabled,
                                orderFields[selectedMaterialIndex].id
                              )}
                            />
                          )}
                        </div>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Hidden mdUp mdDown={!!activePopup}>
            {!noValidMaterial ? (
              <ShowOrderSummaryLegacySection
                onOpenOrderSummary={() => setActivePopup('OrderSummary')}
                disabled={
                  !methods.formState.isValid || noValidMaterial || isOrderRequestsCacheLoading
                }
              />
            ) : (
              <Grid item xs={12}>
                <Card>
                  <SummaryNoValidMaterials invalidMaterialOptions={invalidMaterialOptions} />
                </Card>
              </Grid>
            )}
          </Hidden>
        </Grid>
      </Form>
    </FormProvider>
  )
}
