import {Typography} from '@hconnect/uikit'
import {HCThemeType} from '@hconnect/uikit/src/lib/HCTheme.types'
import {Box, makeStyles} from '@material-ui/core'
import currencyFormatter from 'currency-formatter'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getPercentageAsString} from '../Cashbalance/Cashbalance.utils'

export const useIsOverflow = (
  ref: React.MutableRefObject<HTMLDivElement | undefined>,
  callback?: (isOverflow: boolean) => void
) => {
  const [isOverflow, setIsOverflow] = React.useState<boolean | undefined>(undefined)

  React.useLayoutEffect(() => {
    const {current} = ref

    const trigger = () => {
      const hasOverflow = current ? current.scrollWidth > current.clientWidth : false

      setIsOverflow(hasOverflow)

      if (callback) callback(hasOverflow)
    }

    if (current) {
      trigger()
    }
  }, [callback, ref])

  return isOverflow
}

const useStyles: any = makeStyles((theme: HCThemeType) => ({
  overDraftBracket: {
    borderLeft: `1px solid ${theme.palette.error.main}`,
    borderTop: `1px solid ${theme.palette.error.main}`,
    borderRight: `1px solid ${theme.palette.error.main}`
  },
  cashBracket: {
    borderLeft: `1px solid ${theme.palette.text.primarySoft}`,
    borderTop: `1px solid ${theme.palette.text.primarySoft}`,
    borderRight: `1px solid ${theme.palette.text.primarySoft}`
  }
}))

enum CashBalanceType {
  deposit = 'deposit',
  creditLine = 'creditLine',
  exceededBy = 'exceededBy'
}
type CashBalanceTypes = keyof typeof CashBalanceType

interface InfoBracketProps {
  title: string
  amount: number
  totalAmount: number
  openAmount: number
  currency: string
  spacerBottom?: boolean
  spacerTop?: boolean
  isOverDraft?: boolean
  minified?: boolean
  cashBalanceType?: CashBalanceTypes
  responsive?: boolean
  onDark?: boolean
}

// eslint-disable-next-line complexity
export const InfoBracket: React.FC<InfoBracketProps> = ({
  title,
  amount,
  totalAmount,
  spacerBottom,
  spacerTop,
  isOverDraft,
  currency,
  minified,
  cashBalanceType,
  responsive,
  onDark
}) => {
  const classes = useStyles()
  const bracketRef = React.useRef<HTMLDivElement>()
  const isOverflow = useIsOverflow(bracketRef)
  const bracketWidth = getPercentageAsString(amount, totalAmount, 2)
  const valueExceedsWidget = parseInt(bracketWidth) <= 15
  const {
    i18n: {language}
  } = useTranslation()
  const alignItems = (type?: CashBalanceTypes, responsive?: boolean, isOverflow?: boolean) => {
    if (type === CashBalanceType.creditLine) {
      return responsive && isOverflow ? 'flex-start' : 'center'
    }
    if (type === CashBalanceType.exceededBy) {
      return responsive && isOverflow ? 'flex-end' : 'center'
    }
    if (type === CashBalanceType.deposit) {
      return valueExceedsWidget ? 'flex-start' : 'center'
    }
    return 'center'
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={`${bracketWidth}%`}
      alignItems={alignItems(cashBalanceType, responsive, isOverflow)}
      component="div"
      {...{ref: bracketRef}}
    >
      {spacerTop ? <Box p={1} /> : null}

      <Typography
        customVariant="captionBold"
        customColor={
          responsive ? 'textPrimarySoft' : isOverDraft && minified ? 'textError' : 'textSecondary'
        }
        noWrap
        style={{...(responsive ? {textTransform: 'uppercase'} : {})}}
      >
        {title}
      </Typography>
      <Typography
        customVariant="captionBold"
        color={onDark ? 'textSecondary' : responsive ? 'textPrimary' : 'textSecondary'}
      >
        {currencyFormatter.format(amount, {
          code: currency,
          locale: language
        })}
      </Typography>

      {isOverDraft ? (
        <Box height={12} width="100%" className={classes.overDraftBracket} />
      ) : (
        <Box height={12} width="100%" className={classes.cashBracket} />
      )}
      {spacerBottom ? <Box p={1} /> : null}
    </Box>
  )
}

interface InfoBracketContainerProps {
  deposit: number
  openAmount: number
  creditLimit: number
  overDraft?: number
  currency: string
  minified?: boolean
  responsive?: boolean
  onDark?: boolean
}

export const InfoBracketContainer: React.FC<InfoBracketContainerProps> = ({
  deposit,
  openAmount,
  creditLimit,
  overDraft,
  currency,
  minified,
  responsive = false,
  onDark = false
}) => {
  const {t} = useTranslation()
  const isDepositAndCredit = deposit > 0 && creditLimit > 0
  if (overDraft && overDraft > 0) {
    const total = deposit + creditLimit + overDraft
    return (
      <Box
        display="flex"
        justifyContent={minified && overDraft ? 'flex-end' : 'auto'}
        flexDirection="row"
        width="100%"
      >
        {deposit > 0 && !minified ? (
          <InfoBracket
            title={t('overview.cashBalance.deposit')}
            amount={deposit}
            totalAmount={total}
            currency={currency}
            openAmount={openAmount}
            cashBalanceType={CashBalanceType.deposit}
            responsive={responsive}
            onDark={onDark}
          />
        ) : null}
        {!minified ? (
          <InfoBracket
            title={t('overview.cashBalance.creditLine')}
            amount={creditLimit}
            totalAmount={total}
            currency={currency}
            openAmount={openAmount}
            responsive={responsive}
            onDark={onDark}
          />
        ) : null}
        <InfoBracket
          title={t('overview.cashBalance.exceededBy')}
          amount={overDraft}
          totalAmount={total}
          currency={currency}
          openAmount={openAmount}
          isOverDraft
          minified={minified}
          cashBalanceType={CashBalanceType.exceededBy}
          responsive={responsive}
          onDark={onDark}
        />
      </Box>
    )
  }
  if (isDepositAndCredit && !minified) {
    const total = deposit + creditLimit
    return (
      <>
        <Box display="flex" flexDirection="row" width="100%" alignItems="flex-end">
          <InfoBracket
            title={t('overview.cashBalance.deposit')}
            amount={deposit}
            totalAmount={total}
            currency={currency}
            openAmount={openAmount}
            cashBalanceType={CashBalanceType.deposit}
            responsive={responsive}
            onDark={onDark}
          />
          <InfoBracket
            title={t('overview.cashBalance.creditLine')}
            amount={creditLimit}
            totalAmount={total}
            currency={currency}
            openAmount={openAmount}
            responsive={responsive}
            onDark={onDark}
          />
        </Box>
      </>
    )
  }
  if (!minified) {
    return (
      <>
        <InfoBracket
          title={t('overview.cashBalance.creditLine')}
          amount={creditLimit}
          totalAmount={creditLimit}
          openAmount={openAmount}
          currency={currency}
          responsive={responsive}
          onDark={onDark}
        />
      </>
    )
  } else return null
}
