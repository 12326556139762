import React from 'react'
import {useTranslation} from 'react-i18next'
import {batch, connect} from 'react-redux'

import {AppState} from '../../Root.store'
import {selectCustomers} from '../Customers'
import {Customer} from '../Customers/Customers.types'

import {
  removeGlobalProjectFilter,
  removeGlobalSiteFilter,
  setGlobalProjectFilter,
  setGlobalSiteFilter
} from './Projects.action'
import {selectSelectedProjectFilter, selectSelectedSiteFilter} from './Projects.selectors'
import {ProjectWithSites} from './Projects.types'
import ProjectsWithSitesDropdown from './ProjectsWithSitesDropdown'

interface ProjectsSitesFilterType {
  projectFilter: any
  siteFilter: any
  setProjectFilter: (props: any) => void
  setSiteFilter: (props: any) => void
  removeFilter: () => void
  removeProjectFilter: () => void
  removeSiteFilter: () => void
  customers: Customer[]
  onLight?: boolean
}
const ProjectsSitesFilter: React.FC<ProjectsSitesFilterType> = ({
  projectFilter = {},
  siteFilter = {},
  setProjectFilter,
  setSiteFilter,
  removeFilter,
  removeProjectFilter,
  removeSiteFilter,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customers,
  onLight
}) => {
  const {t} = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const handleChangeDropdown = (value: ProjectWithSites | any) => {
    const projectId: string = value && value.projectId && !value.siteId ? value.projectId : null
    const siteId: string = value && value.siteId ? value.siteId : null

    if (projectId) {
      removeSiteFilter()
      setProjectFilter({projectId})
    } else if (siteId) {
      removeProjectFilter()
      setSiteFilter({siteId})
    } else {
      removeFilter()
    }
  }

  return (
    <ProjectsWithSitesDropdown
      label={t('projectsSitesDropdown.label')}
      noSelectionLabel={t('projectsSitesDropdown.noSelectionLabel')}
      onChange={handleChangeDropdown}
      selectedProjectId={projectFilter && projectFilter.projectId}
      selectedSiteId={siteFilter && siteFilter.siteId}
      onLight={onLight}
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  projectFilter: selectSelectedProjectFilter(state),
  siteFilter: selectSelectedSiteFilter(state),
  customers: selectCustomers(state).customers
})

const mapDispatchToProps = (dispatch) => ({
  setProjectFilter: (props) => {
    dispatch(setGlobalProjectFilter(props))
  },
  setSiteFilter: (props) => {
    dispatch(setGlobalSiteFilter(props))
  },
  removeFilter: () => {
    batch(() => {
      dispatch(removeGlobalProjectFilter())
      dispatch(removeGlobalSiteFilter())
    })
  },
  removeProjectFilter: () => {
    dispatch(removeGlobalProjectFilter())
  },
  removeSiteFilter: () => {
    dispatch(removeGlobalSiteFilter())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsSitesFilter)
