import {trackEvent} from '@hconnect/common/logging/Analytics'
import {LookupFilter as Lookup} from '@hconnect/uikit'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import {AppState} from '../../../../Root.store'
import {switchView} from '../MaterialTest.action'
import {LookupMaterialTestItems, LookupSelectItem, MaterialTestView} from '../MaterialTest.enum'
import {
  removeLookupFilter,
  selectCustomerOrderFilter,
  selectLookupFilter,
  setLookupFilter
} from '../MaterialTest.filters'

const DEFAULT_LOOKUP_KEY = LookupSelectItem.ORDER

interface LookupFilterType {
  hasError: boolean | undefined
  setFilter: (props: any) => void
  filter: any
  customer: any
  switchToLookupView: () => void
  switchToDefaultView: () => void
  removeFilter: () => void
  analyticsId?: string
  userId?: string
}

const LookupFilter: React.FC<LookupFilterType> = ({
  hasError,
  setFilter,
  filter = {},
  customer,
  switchToLookupView,
  switchToDefaultView,
  removeFilter,
  analyticsId,
  userId
}) => {
  const {t} = useTranslation()

  interface SelectItem {
    key: LookupMaterialTestItems
    value: string
  }

  const selectItems: SelectItem[] = [
    {
      key: LookupSelectItem.ORDER,
      value: t('order.lookup.orderNo')
    },
    {
      key: LookupSelectItem.TICKET,
      value: t('order.lookup.ticketNo')
    }
  ]

  const inputLabels = {
    [LookupSelectItem.ORDER]: t('order.lookup.labelorderNo'),
    [LookupSelectItem.TICKET]: t('order.lookup.labelticketNo')
  }

  const placeholderLabels = {
    [LookupSelectItem.ORDER]: t('order.lookup.orderPlchld'),
    [LookupSelectItem.TICKET]: t('order.lookup.ticketPlchld')
  }

  // find selected filter from redux store or fallback to the first item in list
  const initialSelectedItem =
    selectItems.find((item) => item.key === filter.entity) || selectItems[0]

  const [placeholder, setPlaceholder] = useState(placeholderLabels[DEFAULT_LOOKUP_KEY])
  const [lookupEntity, setLookupEntity] = useState<LookupMaterialTestItems | undefined>(
    initialSelectedItem.key
  )

  const [lookupLabel, setLookupLabel] = useState<string>()
  const [lookupInputLabel, setInputLookupLabel] = useState<string>()
  const [lookupInput, setLookupInput] = useState(filter.value)

  useEffect(() => {
    setLookupLabel(initialSelectedItem.value)
    setInputLookupLabel(inputLabels[initialSelectedItem.key])
    setLookupInput(filter.value || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.value, t])

  const handleLookupSelectChange = ({key, value}: {key: string; value: string}) => {
    trackEvent('hubLookupSelect', {
      lookupCategory: key,
      lookupSource: 'rmctests',
      customerId: customer?.customerId,
      userId,
      analyticsId
    })
    setLookupInput('')
    setPlaceholder(placeholderLabels[key])
    setLookupLabel(value)
    setInputLookupLabel(inputLabels[key])
    setLookupEntity(key as LookupMaterialTestItems)
  }

  const handleReset = () => {
    setLookupInput('')
    setPlaceholder(placeholderLabels[DEFAULT_LOOKUP_KEY])
    setInputLookupLabel(inputLabels[DEFAULT_LOOKUP_KEY])
    setLookupEntity(DEFAULT_LOOKUP_KEY)
    switchToDefaultView()
    removeFilter()
  }

  const handleLookup = () => {
    const value = (lookupInput || '').trim()
    if (value && lookupLabel) {
      trackEvent('hubLookupExecute', {
        lookupCategory: lookupEntity,
        lookupValue: value,
        lookupSource: 'rmctests',
        customerId: customer?.customerId,
        userId,
        analyticsId
      })
      switchToLookupView()
      setFilter({
        entity: lookupEntity,
        label: lookupLabel,
        value
      })
    }
  }

  return (
    <Lookup
      hasError={hasError}
      data-test-id="order-lookup"
      placeholder={placeholder}
      label={lookupInputLabel}
      inputValue={lookupInput}
      onLookup={handleLookup}
      onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setLookupInput(event.target.value)
      }}
      onSelectChange={handleLookupSelectChange}
      selectedKey={lookupEntity}
      onSelectRenderValue={() => <div style={{marginLeft: 31}}>{lookupLabel}</div>}
      onReset={handleReset}
      selectItems={selectItems}
      {...{style: {height: '100%'}}}
    />
  )
}
const mapStateToProps = (state: AppState) => ({
  filter: selectLookupFilter(state),
  customer: selectCustomerOrderFilter(state)
})
const mapDispatchToProps = (dispatch) => ({
  setFilter: (props) => dispatch(setLookupFilter(props)),
  switchToLookupView: () =>
    dispatch(switchView({view: MaterialTestView.LOOKUP, clearPrevView: true})),
  switchToDefaultView: () =>
    dispatch(
      switchView({
        view: MaterialTestView.DEFAULT,
        clearPrevView: true
      })
    ),
  removeFilter: () => dispatch(removeLookupFilter())
})
export default connect(mapStateToProps, mapDispatchToProps)(LookupFilter)
