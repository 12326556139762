import {Site, Project} from '@hconnect/apiclient'
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useMediaQueryBreakpoints} from '../../../../../common/useMediaQueryBreakpoints'

import {ProjectsSubSitesRow} from './ProjectsSubSitesRow'

type Props = {
  projects: Project[]
  sites: Site[]
  projectsIds: string[]
  sitesIds: string[]
  hasSitesEnabled: boolean
  hasProjectsEnabled: boolean
  selectedProjectsAndSites: string[]
  setSelectedProjectsAndSites: (rows: string[]) => void
  rows: ProjectAndSitesTableProps[]
}

export type ProjectAndSitesTableProps = {
  row: ProjectAndSitesTableRowProps
  isProject?: boolean
  assignedSitesRows?: ProjectAndSitesTableRowProps[]
}

export type ProjectAndSitesTableRowProps = {
  id: string
  name: string
  address: string
  siteNumber?: string
}

export const ProjectsSubSitesSelection: React.FC<Props> = ({
  projectsIds,
  sitesIds,
  hasSitesEnabled,
  hasProjectsEnabled,
  selectedProjectsAndSites,
  setSelectedProjectsAndSites,
  rows
}) => {
  const {sm: isMobile} = useMediaQueryBreakpoints()
  const {t} = useTranslation()

  const calcSiteDisabled = () => {
    if (!hasSitesEnabled) {
      return true
    }

    if (projectsIds.length >= 2) {
      return true
    }

    return false
  }

  const calcProjectDisabled = (projectId: string) => {
    if (!hasProjectsEnabled) {
      return true
    }

    if (sitesIds.length && projectsIds.length) {
      return !projectsIds.includes(projectId)
    }

    return false
  }

  const isProjectOrSiteSelected = (id: string) => selectedProjectsAndSites.indexOf(id) !== -1

  const handleSelectProjectOrSiteClick = (event, id: string) => {
    event.stopPropagation()
    const selectedIndex = selectedProjectsAndSites.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedProjectsAndSites, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedProjectsAndSites.slice(1))
    } else if (selectedIndex === selectedProjectsAndSites.length - 1) {
      newSelected = newSelected.concat(selectedProjectsAndSites.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedProjectsAndSites.slice(0, selectedIndex),
        selectedProjectsAndSites.slice(selectedIndex + 1)
      )
    }

    setSelectedProjectsAndSites(newSelected)
  }

  const tableBody = rows.map((row) =>
    row.assignedSitesRows ? (
      <>
        <ProjectsSubSitesRow
          key={`${row.row.id}-${row.row.address}`}
          id={row.row.id}
          address={row.row.address}
          name={row.row.name}
          checked={isProjectOrSiteSelected(row.row.id)}
          onCheckboxClick={handleSelectProjectOrSiteClick}
          disabled={calcProjectDisabled(row.row.id)}
        />
        {row.assignedSitesRows.map((siteRow) => (
          <ProjectsSubSitesRow
            key={`assigned-${siteRow.id}-${siteRow.address}`}
            id={siteRow.id}
            address={siteRow.address}
            name={siteRow.name}
            checked={isProjectOrSiteSelected(siteRow.id)}
            onCheckboxClick={handleSelectProjectOrSiteClick}
            disabled={calcSiteDisabled()}
            nested={true}
          />
        ))}
      </>
    ) : (
      <ProjectsSubSitesRow
        key={`${row.row.id}-${row.row.address}`}
        id={row.row.id}
        address={row.row.address}
        name={row.row.name}
        checked={isProjectOrSiteSelected(row.row.id)}
        onCheckboxClick={handleSelectProjectOrSiteClick}
        disabled={row.isProject ? calcProjectDisabled(row.row.id) : calcSiteDisabled()}
      />
    )
  )

  const desktopTable = (
    <Table
      style={{
        height: '100%',
        marginTop: '6px'
      }}
    >
      <TableHead
        style={{
          background: 'radial-gradient(70.49% 70.49% at 50% 10.41%, #F0F4F7 0%, #EBEFF2 100%)',
          height: '36px'
        }}
      >
        <TableRow>
          <TableCell style={{paddingTop: 0, paddingBottom: 0, paddingLeft: '54px'}}>
            {t('invitations.projectsAndSites')}
          </TableCell>
          <TableCell style={{paddingTop: 0, paddingBottom: 0}}>
            {t('invitations.address')}
          </TableCell>
          <TableCell style={{paddingTop: 0, paddingBottom: 0}}>{t('invitations.id')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{tableBody}</TableBody>
    </Table>
  )

  const mobileTable = (
    <Table
      style={{
        height: '100%',
        marginTop: '6px'
      }}
    >
      <TableHead
        style={{
          background: 'radial-gradient(70.49% 70.49% at 50% 10.41%, #F0F4F7 0%, #EBEFF2 100%)',
          height: '36px'
        }}
      >
        <TableRow>
          <TableCell style={{paddingTop: 0, paddingBottom: 0, paddingLeft: '54px'}}>
            {t('invitations.projectsAndSites')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{tableBody}</TableBody>
    </Table>
  )

  return <div data-test-id="projectsAndSitesTable">{isMobile ? mobileTable : desktopTable}</div>
}
