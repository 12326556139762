import {User} from '@hconnect/apiclient/src'
import {useFeaturesChecker} from '@hconnect/common/components/FeaturesCheck'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {getRouteByPath, useMobileBreakPoint, CommonHeader, useElementSize} from '@hconnect/uikit'
import {Shell} from '@hconnect/uikit/src/lib2'
import {Box, CircularProgress, makeStyles} from '@material-ui/core'
import {History, Location} from 'history'
import get from 'lodash/get'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {api, mhcUrl} from '../App.global'
import AsyncJobQueue from '../AsyncJobQueue'
import {useMultipleLeads} from '../Hooks/useMultipleLeads'
import {useBranding} from '../Organisms/Branding'
import {getCountry} from '../Organisms/Countries/Countries.actions'
import {selectCustomers} from '../Organisms/Customers'
import {CustomerStateType} from '../Organisms/Customers/Action.types'
import {Features, useFeaturesState} from '../Organisms/Features'
import {
  MaintenanceState,
  useMaintenanceScreen
} from '../Pages/MaintenanceScreen/MaintenanceScreenProvider'
import {useMaintenanceMode} from '../Pages/MaintenanceScreen/useMaintenanceMode'
import {usePermissions} from '../Permissions'
import {useRolesList} from '../Roles'
import {AppState} from '../Root.store'
import {updateUserProfile} from '../UserProfile/UserProfile.actions'

import {AccountMenu} from './AccountMenu/AccountMenu'
import {AnnouncementMessages} from './AnnouncementMessages/AnnouncementMessages'
import {
  checkFeatureFlags,
  getPermittedRouteByPath,
  mapNavLinksToBurgerMenu
} from './common/routesFiltering'
import {CustomerFeedbackSurvey} from './CustomerFeedbackProgramme/CustomerFeedbackSurvey/CustomerFeedbackSurvey'
import HeaderLogo from './HeaderLogo'
import {HubTermsAndPrivacyModal} from './HubTermsAndPrivacyModal'
import {useScreenSizeLabel} from './Responsive.utils'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'sticky',
    zIndex: 1099,
    width: '100%',
    marginTop: -2,
    top: 50,
    [theme.breakpoints.up('sm')]: {
      top: 64
    }
  }
}))

const trackPageView = (
  user: User | null,
  route: string,
  breakpoint: string,
  customerId?: string
) => {
  trackEvent('hubPageView', {
    product: 'hub',
    userId: user?.user_id || '',
    accountId: customerId,
    country: user?.country,
    userLanguage: user?.defaultLocale,
    pageURL: window.location.href,
    pagePath: route,
    refferingUrl: document.referrer,
    breakpoint: breakpoint
  })
}
interface Props {
  children: React.ReactNode
  logout: () => void
  location: Location
  history: History
  isResponsive?: boolean
}

// eslint-disable-next-line complexity
export const Layout = ({children, logout, location, ...props}: Props) => {
  const {t, i18n} = useTranslation()
  const {container} = useStyles()
  const {country: countryFromBranding} = useBranding()
  const [ref, {width}] = useElementSize()
  const user = useSelector((state: AppState) => get(state, ['userProfile', 'userProfile']))
  const country = user?.country || countryFromBranding
  const brandingCountry = country || 'US'
  const {getFeature} = useFeaturesState()
  const [isAccountMenuOpen, setAccountMenuOpen] = useState<boolean>(false)

  const dispatch = useDispatch()
  const {hasPermission} = usePermissions()

  const setDefaultLocale = (username, locale) => {
    dispatch(updateUserProfile(username, locale, 'defaultLocale'))
  }
  const breakpointLabel = useScreenSizeLabel()

  const {grantedPermissionTypes} = usePermissions()
  const {data: roles} = useRolesList()
  const {customers, selectedCustomer} = useSelector<AppState, CustomerStateType>(
    (state: AppState) => selectCustomers(state)
  )
  const {customerId} = selectedCustomer || {}
  const isPartnersEnabled = getFeature('Partners')
  const isMHCEnabled = getFeature('MHCLink')

  const leadSubscriptionsEnabled = useMultipleLeads(isPartnersEnabled, customers)

  const {calcEnabled: isFeatureToggleEnabled} = useFeaturesChecker(api)

  const isMobile = useMobileBreakPoint()

  const {setMaintenanceState} = useMaintenanceScreen()
  const {isFetching: maintenanceModeFetching, data: maintenanceMode} = useMaintenanceMode()

  useEffect(() => {
    // check for maintenance mode
    setMaintenanceState && maintenanceMode && setMaintenanceState(maintenanceMode)
  }, [maintenanceModeFetching])

  useEffect(() => {
    if (user) {
      dispatch(getCountry())
    }
  }, [dispatch, user])

  const inviteReceived = location.pathname.includes('receiveInvite')

  const loading = t('loadingDots')

  const name = get(user, 'name', loading)
  const email = get(user, 'eMail', loading)
  const userDefaultLocale = get(user, 'defaultLocale')

  const countryDefaultLocale = useSelector((state: AppState) =>
    get(state, ['countries', 'defaultLocale'])
  )

  const defaultLocale = userDefaultLocale || countryDefaultLocale

  const locales = useSelector((state: AppState) => get(state, ['countries', 'locales']))

  useEffect(() => {
    if (defaultLocale) {
      void i18n.changeLanguage(defaultLocale)
    }
  }, [i18n, defaultLocale])

  const handleLanguageChange = (language) => {
    setDefaultLocale(email, language)
  }

  if (!user || !locales) {
    return (
      <CircularProgress
        style={{display: 'block', margin: '40px auto'}}
        data-test-id="layout-locales-loader"
      />
    )
  }

  const burgerMenuNavItems = mapNavLinksToBurgerMenu(
    '/',
    grantedPermissionTypes,
    {
      blacklist: {countries: [brandingCountry]}
    },
    roles,
    isFeatureToggleEnabled,
    t,
    getRouteByPath,
    checkFeatureFlags,
    getPermittedRouteByPath,
    false,
    leadSubscriptionsEnabled
  )

  const closeOverlays = () => {
    setAccountMenuOpen(false)
  }

  const checkForMaintenanceScreen = (maintenanceState: MaintenanceState | undefined) => {
    setMaintenanceState && maintenanceState && setMaintenanceState(maintenanceState)
  }

  const mhcNav = isMHCEnabled && hasPermission('ASSIGN_ROLES') ? mhcUrl : undefined
  return (
    <Shell
      {...props}
      boxed={false}
      showScrollToTop={false}
      onDark={false}
      isResponsive
      compact={false}
      zIndex={200}
      header={
        <div ref={ref} style={{width: '100%', height: isMobile ? '60px' : '65px'}}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pl={1.5}
            width="100%"
          >
            {!isMobile ? (
              <Box>
                <HeaderLogo />
              </Box>
            ) : null}
            {!inviteReceived && (
              <Box sx={{width: isMobile ? null : '100%'}}>
                <CommonHeader
                  appName="HConnect"
                  navItems={burgerMenuNavItems}
                  analytics={(route: string) =>
                    trackPageView(user, route, breakpointLabel, customerId)
                  }
                  parentWidth={width}
                  disableAppSelect
                  closeOverlays={closeOverlays}
                  navItemSelected={() => checkForMaintenanceScreen(maintenanceMode)}
                />
              </Box>
            )}
            {isMobile ? (
              <Box>
                <HeaderLogo withoutBranding />
              </Box>
            ) : null}
            {!inviteReceived ? (
              <AccountMenu
                isOpen={isAccountMenuOpen}
                setOpen={setAccountMenuOpen}
                name={name || ''}
                email={email || ''}
                customers={customers}
                locales={{
                  defaultLocale: defaultLocale || locales[0].code,
                  locales
                }}
                selectLanguage={(language) => {
                  handleLanguageChange(language)
                }}
                logout={logout}
                mhcUrl={mhcNav}
              />
            ) : null}
          </Box>
        </div>
      }
    >
      <>
        <Box className={container}>
          <HubTermsAndPrivacyModal />
          <AnnouncementMessages />
          <AsyncJobQueue />
        </Box>
        {children}
        <Features name="CustomerFeedbackSurvey">
          <CustomerFeedbackSurvey />
        </Features>
      </>
    </Shell>
  )
}
