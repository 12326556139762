import {Delivery} from '../../Pages/Finance/Invoices/Delivery.types'
import {Invoice} from '../../Pages/Finance/Invoices/Invoice.types'
import {PERMISSIONS_FETCH_SUCCESS, PermissionsActionTypes} from '../../Permissions/Action.types'
import {concatIds} from '../../util/reducer.helper'
import {
  CUSTOMERS_FETCH_FAILURE,
  CUSTOMERS_FETCH_REQUEST,
  CUSTOMERS_FETCH_RESPONSE
} from '../Customers'
import {CustomerActionTypes} from '../Customers/Action.types'
import {FilterActionType, createFiltersReducer, initialFiltersState} from '../Filters'
import {getGlobalResultsPerPage} from '../ResultsPerPage'

import {
  DELIVERIES_INVOICES_FETCH_FAILURE,
  DELIVERIES_INVOICES_FETCH_REQUEST,
  DELIVERIES_INVOICES_FETCH_SUCCESS,
  INVOICES,
  INVOICES_CLEAR,
  INVOICES_FETCH_FAILURE,
  INVOICES_FETCH_REQUEST,
  INVOICES_FETCH_SUCCESS,
  INVOICES_LOAD_MORE,
  INVOICES_MULTI_DOWNLOAD_CHECK_ALL_DELIVERIES,
  INVOICES_MULTI_DOWNLOAD_CHECK_ALL_INVOICES,
  INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_ALL,
  INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_DELIVERY,
  INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_INVOICE,
  INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_TABLEROW,
  INVOICES_MULTI_DOWNLOAD_TOGGLE_SWITCH_MULTIPLE_DOWNLOAD,
  INVOICES_SET_EXPANDED,
  INVOICES_SET_RESULTS_PER_PAGE,
  INVOICES_SET_SORT_KEY,
  InvoicesActionTypes,
  InvoicesStateType
} from './Action.types'
import {getServicesOrSurcharges, mergeLineItemsInfoIntoBreakdown} from './Invoices.helper'

const filterReducer = createFiltersReducer(INVOICES)

export const initialInvoicesState: InvoicesStateType = {
  byId: {},
  allIds: [],
  expandedIds: [],
  checkedInvoiceTableRows: [],
  checkedInvoiceIds: [],
  uncheckedDeliveryIds: [],
  isMultipleDownloadActive: false,
  sortOrder: {
    key: 'invoiceDate',
    asc: false
  },
  skip: 0,
  limit: getGlobalResultsPerPage() || 10,
  showLoadMore: false,
  isFetching: false,
  isOnlyInvoices: false,
  isOnlyDeliveries: false,
  totalCount: 0,
  totalGrossValue: 0,
  ...initialFiltersState
}

// eslint-disable-next-line complexity
export default (
  state = initialInvoicesState,
  action: InvoicesActionTypes | FilterActionType | CustomerActionTypes | PermissionsActionTypes
) => {
  switch (action.type) {
    case PERMISSIONS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: true
      }

    case CUSTOMERS_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true
      }

    case CUSTOMERS_FETCH_RESPONSE:
      return {
        ...state,
        isFetching: false
      }

    case CUSTOMERS_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false
      }

    case INVOICES_CLEAR:
      return {
        ...initialInvoicesState,
        sortOrder: {
          ...state.sortOrder
        },
        limit: state.limit
      }

    case INVOICES_SET_SORT_KEY: {
      const asc = !(state.sortOrder.key === action.payload && state.sortOrder.asc)
      return {
        ...state,
        skip: 0,
        sortOrder: {
          key: action.payload,
          asc
        }
      }
    }

    case INVOICES_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true
      }

    case INVOICES_FETCH_SUCCESS: {
      const collectionIds = action.payload ? action.payload.map((item) => item.invoiceId) : []
      return {
        ...state,
        isFetching: false,
        showLoadMore: action.showLoadMore,
        byId: {
          ...state.byId,
          ...action.payload.reduce<{[key: string]: any}>(
            (prev: {[key: string]: Invoice}, item: Invoice) => ({
              ...prev,
              [item.invoiceId]: {
                deliveriesLoading: false,
                ...item,
                ...mergeLineItemsInfoIntoBreakdown(item),
                servicesOrSurcharges: getServicesOrSurcharges(item)
              }
            }),
            {}
          )
        },
        allIds: concatIds(state.allIds, collectionIds),
        totalCount: action.totalCount,
        totalGrossValue: action.totalGrossValue
      }
    }

    case INVOICES_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false
      }

    case INVOICES_LOAD_MORE:
      return {
        ...state,
        skip: state.skip + state.limit
      }

    case INVOICES_SET_EXPANDED:
      return {
        ...state,
        expandedIds: [
          ...(action.expanded
            ? state.expandedIds.concat(action.invoiceId || '')
            : state.expandedIds.filter((invoiceId) => invoiceId !== action.invoiceId))
        ]
      }

    case DELIVERIES_INVOICES_FETCH_REQUEST:
      if (action.invoiceId) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [action.invoiceId]: {
              ...state.byId[action.invoiceId],
              deliveriesLoading: true
            }
          }
        }
      }
      return state

    case DELIVERIES_INVOICES_FETCH_FAILURE:
      if (action.invoiceId) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [action.invoiceId]: {
              ...state.byId[action.invoiceId],
              deliveriesLoading: false,
              deliveries: []
            }
          }
        }
      }
      return state

    case DELIVERIES_INVOICES_FETCH_SUCCESS: {
      const {invoiceId, payload} = action
      if (invoiceId) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [invoiceId]: {
              ...state.byId[invoiceId],
              deliveriesLoading: false,
              deliveries: payload.map((delivery: Delivery) => delivery.deliveryId)
            }
          },
          uncheckedDeliveryIds: state.checkedInvoiceTableRows.includes(invoiceId)
            ? state.uncheckedDeliveryIds
            : [
                ...state.uncheckedDeliveryIds.filter(
                  (deliveryId) => !payload?.find((delivery) => delivery.deliveryId === deliveryId)
                ),
                ...payload.map((delivery: Delivery) => delivery.deliveryId)
              ]
        }
      }
      return state
    }

    case INVOICES_MULTI_DOWNLOAD_TOGGLE_SWITCH_MULTIPLE_DOWNLOAD: {
      return {
        ...state,
        isMultipleDownloadActive: !state.isMultipleDownloadActive
      }
    }

    case INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_ALL: {
      const {checked} = action
      if (checked === 1) {
        const {allIds} = state

        // -1, remove all invoiceIds, all rowIds, all deliveryIds

        return {
          ...state,
          isOnlyInvoices: false,
          checkedInvoiceIds: [],
          checkedInvoiceTableRows: [],
          uncheckedDeliveryIds: allIds
            .map((invoiceId: string) => state.byId[invoiceId].deliveries || [])
            .flat()
        }
      }
      // 1 add all invoiceIds, all rowIds, remove all deliveryIds
      return {
        ...state,
        isOnlyInvoices: false,
        checkedInvoiceIds: [...state.allIds],
        checkedInvoiceTableRows: [...state.allIds],
        uncheckedDeliveryIds: []
      }
    }

    case INVOICES_MULTI_DOWNLOAD_CHECK_ALL_INVOICES: {
      return {
        ...state,
        isOnlyInvoices: true,
        isOnlyDeliveries: false,
        checkedInvoiceIds: [...state.allIds]
      }
    }

    case INVOICES_MULTI_DOWNLOAD_CHECK_ALL_DELIVERIES: {
      return {
        ...state,
        isOnlyInvoices: false,
        isOnlyDeliveries: true,
        uncheckedDeliveryIds: [],
        checkedInvoiceTableRows: [...state.allIds]
      }
    }

    case INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_TABLEROW: {
      const {rowId, checked} = action
      const invoice = state.byId[rowId]

      if (checked === 1) {
        // remove rowId and invoiceId from collection, checked = -1
        // remove deliveryIds for fully loaded invoices

        // if fully loaded, add all deliveries to uncheckedDeliveryIds
        // if not fully loaded, do nothing to uncheckedDeliveryIds
        return {
          ...state,
          checkedInvoiceIds: state.checkedInvoiceIds.filter((invoiceId) => invoiceId !== rowId),
          checkedInvoiceTableRows: state.checkedInvoiceTableRows.filter(
            (invoiceId) => invoiceId !== rowId
          ),
          uncheckedDeliveryIds: [
            ...state.uncheckedDeliveryIds.filter(
              (deliveryId) => !invoice?.deliveries?.includes(deliveryId)
            ),
            ...(invoice?.deliveries || [])
          ]
        }
      }
      // add rowId and invoiceId to collection, checked = 1
      // remove deliveryIds if invoice is expandend
      return {
        ...state,
        checkedInvoiceIds: [...state.checkedInvoiceIds, rowId],
        checkedInvoiceTableRows: [
          ...state.checkedInvoiceTableRows.filter((invoiceId) => invoiceId !== rowId),
          rowId
        ],
        uncheckedDeliveryIds: [
          ...state.uncheckedDeliveryIds.filter(
            (deliveryId) => !invoice?.deliveries?.includes(deliveryId)
          )
        ]
      }
    }

    case INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_DELIVERY: {
      const {checked, deliveryId} = action

      if (checked) {
        // add deliveryId to uncheckedDeliveryIds
        return {
          ...state,
          isOnlyInvoices: false,
          checkedInvoiceTableRows: [...state.allIds],
          uncheckedDeliveryIds: [
            ...state.uncheckedDeliveryIds.filter((id) => id !== deliveryId),
            deliveryId
          ]
        }
      }
      // remove deliveryId from uncheckedDeliveries
      return {
        ...state,
        isOnlyInvoices: false,
        checkedInvoiceTableRows: [...state.allIds],
        uncheckedDeliveryIds: state.uncheckedDeliveryIds.filter((id) => id !== deliveryId)
      }
    }

    case INVOICES_MULTI_DOWNLOAD_TOGGLE_CHECKBOX_INVOICE: {
      const {invoiceId, checked} = action
      if (checked) {
        // remove invoiceId, checked = false
        return {
          ...state,
          // isOnlyInvoices: false,
          // isOnlyDeliveries: false,
          checkedInvoiceIds: state.checkedInvoiceIds.filter((id) => id !== invoiceId)
        }
      }
      // add invoiceId. checked = true
      return {
        ...state,
        // isOnlyInvoices: false,
        // isOnlyDeliveries: false,
        checkedInvoiceIds: [...state.checkedInvoiceIds.filter((id) => id !== invoiceId), invoiceId]
      }
    }
    case INVOICES_SET_RESULTS_PER_PAGE:
      return {
        ...state,
        limit: action.payload
      }

    default:
      if ('context' in action) {
        return {
          ...state,
          ...filterReducer(state, action),
          skip: 0
        }
      }
      return state
  }
}
