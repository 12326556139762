import {Card, CardContent} from '@material-ui/core'
import {AxiosError} from 'axios'
import {get, isEmpty, pick} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from 'react-query'

import {usePersistContact} from '../../../common/react-query/hooks/mutations/usePersistContact'
import {usePersistOrderRequests} from '../../../common/react-query/hooks/mutations/usePersistOrderRequests'
import {BusinessLineType} from '../../../common/types'
import {useOrderIntakeStyles} from '../../../Hooks/OrderIntake/useOrderIntakeStyles'
import {ConfirmationLegacyScreen} from '../../../OrderIntake/components/ConfirmationLegacyScreen'
import {ShippingType} from '../../../OrderIntake/declarations/OrderIntake.enums'
import {useBulkCementOrderIntake} from '../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {BulkOrderFormValues} from '../../BulkOrdersFormValues'
import {PersistedOrderRequest} from '../../declarations/types'
import {OptionsKeys} from '../../Options'
import {CollectionProcessingOrderLegacyCard} from '../../components/Collect/CollectionProcessingCardLegacy'

interface HubErrorResponseData {
  detail: string
  status: string
  title: string
}

interface Props {
  businessLine: BusinessLineType
  onClose: () => void
}

export const CollectOrderIntakeRequestLegacyConfirmation: React.FC<Props> = ({onClose, businessLine}) => {
  const {t} = useTranslation()
  const classes = useOrderIntakeStyles()
  const queryClient = useQueryClient()
  const optionsReloaded = useRef(false)
  const [customErrorMessage, setCustomErrorMessage] = useState<string | null>('')
  const {collections, orderSummary} = useBulkCementOrderIntake()
  const {watch} = useFormContext<BulkOrderFormValues>()

  const mainPhone = get(collections[0], 'payload.contact.mainPhone', '')
  const backupPhone = get(collections[0], 'payload.contact.backupPhone', '')
  const userFullName = get(collections[0], 'payload.contact.fullName', '')
  const [showError, setShowError] = useState<boolean>(false)
  const siteNumber = watch('selectedSite.shippingAddress.siteNumber')
  const contact = watch('contact')
  const {mutate: persistContact} = usePersistContact()
  const {mutate: persistOrderRequests} = usePersistOrderRequests()
  useEffect(() => {
    if (isEmpty(collections)) return
    persistContact({siteNumber, contact})
    persistOrderRequests({
      siteNumber,
      orderRequests: [
        ...collections.map((collection) => ({
          ...(pick(collection.payload, [
            'materialNumber',
            'materialEnteredNumber',
            'materialDescription',
            'capacity',
            'deliveryTime',
            'customerReference',
            'contractItemPositionNumber',
            'isSendingConfirmationEmailUnChecked',
            'plantName'
          ]) as PersistedOrderRequest)
        }))
      ]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections])

  const onItemError = (error: AxiosError) => {
    setShowError(true)

    // 'One or more errors occurred while creating the order, possible reasons: InvalidSlot'
    if (error.response?.data) {
      const errorData = error.response.data as HubErrorResponseData
      if (errorData.detail && errorData.detail.indexOf('InvalidSlot') > -1) {
        setCustomErrorMessage('invalidSlot')
        if (!optionsReloaded.current) {
          void queryClient.invalidateQueries(OptionsKeys.all())
          optionsReloaded.current = true
        }
      } else {
        setCustomErrorMessage(null)
      }
    }
  }

  return (
    <ConfirmationLegacyScreen
      data-test-id="collect-order-intake-confirmation-screen"
      hideTitle={showError}
      summaryItems={collections.map((collection, index) => {
        return (
          <Card
            variant="outlined"
            className={classes.confirmationSummaryItem}
            key={`Collect-Item-Order-Confirmation-Processing-${collection.payload.siteNumber}-${collection.payload.materialEnteredNumber}-${index}`}
          >
            <CardContent>
              <CollectionProcessingOrderLegacyCard
                businessLine={businessLine}
                collection={collection}
                itemOrder={`${t('orderIntake.collection')} ${index + 1}`}
                onError={onItemError}
                customErrorMessage={
                  customErrorMessage
                    ? t(`orderIntake.customErrorMessage.${customErrorMessage}`)
                    : undefined
                }
              />
            </CardContent>
          </Card>
        )
      })}
      date={orderSummary[0]?.createdOn}
      onClose={onClose}
      mainPhone={mainPhone}
      backupPhone={backupPhone}
      userFullName={userFullName}
      shippingType={ShippingType.COLLECT}
      itemsNumber={collections.length}
    />
  )
}
