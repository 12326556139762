import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {CustomerStateType, selectCustomers} from '../../Organisms/Customers'
import {setFilter} from '../../Pages/Order/Order.action'
import {AppState} from '../../Root.store'

import SupplierNameFilter from './SupplierNameFilter'
import {useSupplierNamesFromCustomer} from './useGetSupplierName'
import {selectOrders} from '../../Organisms/Orders'
import {RootState} from '../../App.reducer'

const SupplierNameFilterOrders: React.FC = () => {
  const customerState = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  const selectedCustomer = customerState.selectedCustomer
  const {supplierFilterNames} = useSelector((state: RootState) => selectOrders(state).filters)

  const {
    data: supplierNames,
    error: supplierNamesError,
    isLoading: supplierNamesIsLoading
  } = useSupplierNamesFromCustomer(selectedCustomer?.customerId || '')
  const dispatch = useDispatch()

  const setSelectedOrgUnits = (orgUnitIds: string, selectedList: string[]) => {
    const filterValue = selectedList.length === supplierNames.length ? '' : orgUnitIds
    dispatch(setFilter('orgUnitIds', filterValue))
    dispatch(setFilter('supplierFilterNames', selectedList))
  }

  if (!supplierNames || supplierNamesError || supplierNamesIsLoading) return null
  return (
    <SupplierNameFilter
      supplierNames={supplierNames}
      setSelectedOrgUnits={setSelectedOrgUnits}
      selectedSuppliers={supplierFilterNames}
    />
  )
}

export default SupplierNameFilterOrders
