import {DatePicker, lastThirtyDays} from '@hconnect/uikit'
import {Moment} from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import {useBranding} from '../../../../Organisms/Branding'
import {AppState} from '../../../../Root.store'
import {switchView} from '../MaterialTest.action'
import {MaterialTestView} from '../MaterialTest.enum'
import {removeLookupFilter, selectDateFilter, setDateFilter} from '../MaterialTest.filters'

interface DateFilterType {
  filter: {startDate: Moment; endDate: Moment}
  setFilter: (props: {startDate: string | null; endDate: string | null}) => void
}
const DateFilter: React.FC<DateFilterType> = ({filter = {}, setFilter, ...props}) => {
  const {country} = useBranding()
  const {startDate, endDate} = filter || {}
  const {t} = useTranslation()
  // TODO@igor Define quick selection here
  const getDatePickerQuickSelectionItems = (locale: string) => [
    {
      key: 'last-30-days',
      title: t('invoice.datePicker.lastThirtyDays'),
      type: 'range',
      dates: lastThirtyDays(locale)
    }
  ]

  return (
    <DatePicker
      data-test-id="materialtest-filter-datepicker"
      aria-label="material test datepicker"
      startDate={startDate}
      endDate={endDate}
      hideClearButton
      {...(country === 'AU' && {
        maxDayRange: 31,
        maxDayRangeInfoMessage: t('datePicker.maxDayRange')
      })}
      handleDateChange={({startDate, endDate}) => {
        setFilter({
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null
        })
      }}
      getQuickSelectionItems={getDatePickerQuickSelectionItems}
      {...props}
    />
  )
}
const mapStateToProps = (state: AppState) => ({
  filter: selectDateFilter(state)
})
const mapDispatchToProps = (dispatch) => ({
  setFilter: (props) => {
    dispatch(
      switchView({
        view: MaterialTestView.DEFAULT,
        clearPrevView: true
      })
    )
    dispatch(removeLookupFilter())
    dispatch(setDateFilter(props))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(DateFilter)
