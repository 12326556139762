import {
  ASYNC_JOB_ADD,
  ASYNC_JOB_ADD_TO_HISTORY,
  ASYNC_JOB_DELETE,
  ASYNC_JOB_REPORT,
  ASYNC_JOB_UPDATE,
  AsyncJobQueueActionTypes,
  AsyncJobQueueState
} from './AsyncJobQueue.types'

const initialState: AsyncJobQueueState = {
  jobs: [],
  jobHistory: [],
  reportedJobs: []
}

export default (state = initialState, action: AsyncJobQueueActionTypes) => {
  switch (action.type) {
    case ASYNC_JOB_ADD: {
      const newJob = action.data
      return {
        ...state,
        jobs: [newJob, ...state.jobs]
      }
    }

    case ASYNC_JOB_REPORT: {
      const reportedJobId = action.id
      return {
        ...state,
        reportedJobs: [reportedJobId, ...state.reportedJobs]
      }
    }

    case ASYNC_JOB_UPDATE: {
      return {
        ...state,
        jobs: state.jobs.map((job) => (job.id === action.data.id ? action.data : job))
      }
    }

    case ASYNC_JOB_ADD_TO_HISTORY: {
      const newHistoryJob = action.data
      return {
        ...state,
        jobHistory: [newHistoryJob, ...state.jobHistory]
      }
    }

    case ASYNC_JOB_DELETE: {
      const attr = action.name ? 'name' : 'id'
      return {
        ...state,
        jobs: [...state.jobs.filter((job) => job[attr] !== action[attr])]
      }
    }

    default:
      return state
  }
}
