/* eslint-disable complexity */
import {Modal, Grid, Typography, SectionHeader, useNotification} from '@hconnect/uikit'
import {Box, Card} from '@material-ui/core'
import {Dialog, DialogContent, Hidden, Paper, Stack} from '@mui/material'
import parsePhoneNumberFromString, {CountryCode} from 'libphonenumber-js'
import {isEmpty, some, values, isEqual, uniq, first, partial, partialRight} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useForm, useFieldArray, FormProvider, Controller, SubmitHandler} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import distributedCacheLoadingImage from '../../../Assets/distributedCacheLoading.svg'
import noValidMaterialImage from '../../../Assets/noValidMaterials/no-delivery-placeholder.svg'
import {trackEvent} from '../../../common/analytics'
import {usePersistedContact} from '../../../common/react-query/hooks/queries/usePersistedContact'
import {usePersistedOrderRequests} from '../../../common/react-query/hooks/queries/usePersistedOrderRequests'
import {invalidateHubQuery} from '../../../common/react-query/QueryProvider'
import {useOrderIntakeStyles} from '../../../Hooks/OrderIntake/useOrderIntakeStyles'
import {SiteDropdown} from '../../../OrderIntake/components/SiteDropdown'
import {useFeaturesState} from '../../../Organisms/Features'
import {useBulkCementOrderIntake} from '../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {
  checkEmptyTimeZone,
  generateGuid,
  getCurrentTimeStamp,
  getDefaultMaterialOptions,
  getTodaysCutOffTime,
  isCurrentTimeAfterCutOff,
  isDateAvailable
} from '../../../Organisms/OrderIntake/utils'
import {BulkOrderFormValues} from '../../BulkOrdersFormValues'
import {AddAnotherLegacyButton} from '../../components/AddAnotherLegacyButton'
import {
  PersistedMaterialsAction,
  PersistedMaterialsBanner
} from '../../components/PersistedMaterialsDialog/PersistedMaterialsBanner'
import {PersistedMaterialsDialog} from '../../components/PersistedMaterialsDialog/PersistedMaterialsDialog'
import {SummaryNoValidMaterials} from '../../components/SummaryNoValidMaterials'
import {isTwelveHoursFormat} from '../../components/TimeScroller/TimeScroller.utils'
import {DEFAULT_CUSTOMER_REFERENCE} from '../../declarations/constants'
import {ShippingType} from '../../declarations/OrderIntake.enums'
import {
  OrderIntakeMaterialOptionPayload,
  OrderRequest,
  PersistedOrderRequest,
  QuantityType
} from '../../declarations/types'
import {useBulkOrderFormChangeHandlerWithMethods} from '../../FormChangeHandlers'
import {useOrderIntakeData} from '../../hooks'
import {useActivePopup} from '../../hooks/useActivePopup'
import * as options from '../../Options'
import {isInvalidOrderIntakeOption} from '../../Options/query'
import {ContactLegacySection} from '../containers/ContactLegacySection'
import {MaterialOrderLegacyEditor} from '../containers/MaterialOrderEditor/MaterialOrderLegacyEditor'
import {OrderSummaryLegacySection} from '../containers/OrderSummaryLegacySection'
import {useHandleClose} from '../hooks/useHandleClose'
import {useMaterialEditorController} from '../hooks/useMaterialEditorController'
import {
  areAllCustomerReferencesSame,
  getMaterialOptionsByInvalidity,
  isMaterialInvalidBySlots,
  mergePersistedContact,
  mergePersistedOrders
} from '../utils'


import {DeliveryOrderIntakeRequestLegacyConfirmation} from './DeliveryOrderIntakeRequestLegacyConfirmation'
import {DeliverMaterialLegacyForm} from './DeliverMaterialLegacyForm'
import {ShowOrderSummaryLegacySection} from '../../components/ShowOrderSummaryLegacyBtn'

export const BulkCementDeliverOrderLegacyForm = () => {
  const classes = useOrderIntakeStyles()
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {
    customerId,
    userId,
    userPrimaryOrganisationalUnit,
    userFullName,
    userProfileCountry,
    features: {
      orderIntakeCustomerReferenceField,
      hasSiteContactNameAndPhone,
      isMaterialPersistency,
      isContactPersistency
    }
  } = useOrderIntakeData()
  const {getFeature} = useFeaturesState()
  const {notify} = useNotification()

  const isSlotsManagementEnabled = getFeature('OrderIntakeSlotsDeliver')
  const useConfigurableSlots = getFeature('OrderIntakeUseConfigurableSlots')
  const applyMaterialEnteredDescription = getFeature('OrderIntakeMaterialEnteredDescription')
  const applyContractItemDescription = getFeature('OrderIntakeContractItemDescription')

  const configuration = {
    isSlotsManagementEnabled,
    useConfigurableSlots,
    nextDayCollect: false,
    applyMaterialEnteredDescription,
    applyContractItemDescription
  }

  const {setDeliveries, slotConfiguration} = useBulkCementOrderIntake()
  const {data: deliverOptions} = options.useDeliveryOptions(configuration, customerId)
  const {data: defaultDelivery} = options.useDefaultDelivery(customerId, configuration)
  const defaultBusinessLine = options.getBusinessLine(deliverOptions)

  const siteOptions = values(deliverOptions)
  const methods = useForm<BulkOrderFormValues>({
    mode: 'all',
    defaultValues: {
      selectedSite: siteOptions[0],
      orders: [],
      driverInstructions: '',
      isConfirmationMailChecked: true,
      contact: {
        mainPhone: undefined,
        fullName: undefined,
        backupPhone: undefined,
        carbonCopyEmail: undefined
      },
      isOrderRequestConfirmed: false,
      isBackupPhoneVisible: false
    }
  })

  const {
    fields: orderFields,
    append: appendOrder,
    remove: deleteOrder,
    replace: replaceOrders
  } = useFieldArray({
    control: methods.control,
    name: 'orders'
  })

  const {selectedSite, orders} = methods.watch()

  const siteNumber = methods.watch('selectedSite.shippingAddress.siteNumber')
  const {data: persistedContact} = usePersistedContact(siteNumber)
  const {data: persistedOrderRequests, isFetching: isOrderRequestsCacheLoading} =
    usePersistedOrderRequests(siteNumber)

  useEffect(() => {
    setOrderSessionEventId(generateGuid())
  }, [])

  const formChangeHandlers = useBulkOrderFormChangeHandlerWithMethods(methods)

  useEffect(() => {
    if (
      !deliverOptions ||
      !userFullName ||
      !defaultDelivery ||
      isInvalidOrderIntakeOption(defaultDelivery)
    )
      return

    const orderRequestBySiteNumber = orders.find((or) => or.payload.siteNumber === siteNumber)
    const orderRequests = orderRequestBySiteNumber ? [orderRequestBySiteNumber] : [defaultDelivery]
    const contact = mergePersistedContact(selectedSite, persistedContact, isContactPersistency)

    methods.setValue(
      'isConfirmationMailChecked',
      !first(persistedOrderRequests)?.isSendingConfirmationEmailUnChecked ?? true
    )

    formChangeHandlers.onSiteChange(siteNumber, deliverOptions, defaultDelivery)

    replaceOrders(
      orderRequests.map((orderRequest) => ({
        ...orderRequest,
        payload: {
          ...orderRequest.payload,
          customerReference: orderRequest.payload.customerReference || DEFAULT_CUSTOMER_REFERENCE
        },
        initialFullName: userFullName
      }))
    )

    methods.reset({
      ...methods.getValues(),
      isOrderRequestConfirmed: false,
      contact: {
        mainPhone: contact.mainPhone
          ? (parsePhoneNumberFromString(contact.mainPhone, userProfileCountry as CountryCode)
              ?.number as string)
          : '',
        backupPhone: contact.backupPhone
          ? (parsePhoneNumberFromString(contact.backupPhone, userProfileCountry as CountryCode)
              ?.number as string)
          : '',
        fullName: contact.fullName ? contact.fullName : userFullName,
        carbonCopyEmail: contact.carbonCopyEmail,
        siteContactName: hasSiteContactNameAndPhone ? contact.siteContactName : ''
      }
    })

    getValidMaterialsForPersistency()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    siteNumber,
    persistedContact,
    persistedOrderRequests,
    userFullName,
    defaultDelivery,
    deliverOptions
  ])

  const [showConfirmationStatusScreen, setShowConfirmationStatusScreen] = useState<boolean>(false)
  const [editorActivationTimeStamp, setEditorActivationTimeStamp] = useState<string>()
  const [orderSessionEventId, setOrderSessionEventId] = useState<string>()
  const [showPersistedMaterialsBanner, setShowPersistedMaterialsBanner] = useState<boolean>(true)
  const [showPersistedMaterialsDialog, setShowPersistedMaterialsDialog] = useState<boolean>(false)
  const [validMaterialsForPersistency, setValidMaterialsForPersistency] = useState<
    OrderIntakeMaterialOptionPayload[]
  >([])
  const [existsValidPersistedMaterials, setExistsValidPersistedMaterials] = useState<boolean>(false)

  useEffect(() => {
    checkValidPersistedMaterials()
  }, [validMaterialsForPersistency, persistedOrderRequests])

  const getValidMaterialsForPersistency = () => {
    const materialOptions = Object.values(selectedSite.materials).flatMap((m) => m)
    const validMaterialOptions: OrderIntakeMaterialOptionPayload[] = []
    const checkValidityBySlots = isSlotsManagementEnabled && useConfigurableSlots

    const deliveryDate =
      orders.length === 0
        ? (defaultDelivery as OrderRequest)?.payload?.deliveryDate
        : orders[0].payload.deliveryDate

    materialOptions.forEach((material) => {
      const invalidBySlots =
        checkValidityBySlots &&
        slotConfiguration &&
        isMaterialInvalidBySlots(material, deliveryDate, slotConfiguration)
      if (!material.invalid && !invalidBySlots) {
        validMaterialOptions.push(material)
      }
    })

    setValidMaterialsForPersistency(validMaterialOptions)
  }

  const checkValidPersistedMaterials = () => {
    let existsValidMaterial = false
    persistedOrderRequests?.forEach((material) => {
      if (isMaterialValid(material)) existsValidMaterial = true
    })

    setExistsValidPersistedMaterials(existsValidMaterial)
  }

  const isMaterialValid = (material: PersistedOrderRequest) => {
    return validMaterialsForPersistency.some(
      (item) => item.material.materialEnteredNumber === material.materialEnteredNumber
    )
  }

  const handleClose = useHandleClose(
    orders,
    setShowConfirmationStatusScreen,
    methods.reset,
    customerId
  )

  const materialEditorController = useMaterialEditorController()

  const [activePopup, setActivePopup] = useActivePopup(
    materialEditorController.setActiveOrderBox,
    materialEditorController.activeMaterialForm
  )
  if (!deliverOptions || !selectedSite || !defaultDelivery) return null

  const addDelivery = () => {
    appendOrder(orders[orders.length - 1])
    trackEvent('hubOrderIntakeAddOrderRequest')
  }

  const removeDelivery = (index: number) => {
    deleteOrder(index)
    trackEvent('hubOrderIntakeRemoveOrderRequest')
  }
  const quantityType =
    (defaultDelivery as OrderRequest)?.payload?.capacity?.quantityType ?? QuantityType.TruckCapacity
  const onSubmit: SubmitHandler<BulkOrderFormValues> = (data) => {
    replaceOrders(
      orders.map((delivery) => {
        const defaultMaterialOption =
          selectedSite.materials[delivery.payload.materialEnteredNumber][0]

        const businessDays = defaultMaterialOption.dateRange?.businessDays
          ? defaultMaterialOption.dateRange?.businessDays
          : []
        const todaysCutOffTime = getTodaysCutOffTime(businessDays)[0].cutOffTime
        const cutOffTimeStamp = todaysCutOffTime?.timestamp

        if (
          isCurrentTimeAfterCutOff(
            cutOffTimeStamp,
            selectedSite.materials[delivery.payload.materialEnteredNumber][0]?.businessHours
              ?.timeZone
          ) &&
          !isDateAvailable(
            delivery.payload.deliveryDate,
            selectedSite.materials[delivery.payload.materialEnteredNumber][0].dateRange,
            selectedSite.materials[delivery.payload.materialEnteredNumber][0].businessHours.timeZone
          )
        ) {
          return {
            ...delivery,
            isDateNotAvailable: true
          }
        }
        return {
          ...delivery,
          isDateNotAvailable: false
        }
      })
    )

    if (some(orders, 'isDateNotAvailable')) return

    const updatedDeliveries = data.orders.map<OrderRequest>((delivery) => ({
      ...delivery,
      payload: {
        ...delivery.payload,
        additionalDriverInfo: {
          ...delivery.payload.additionalDriverInfo,
          driverInstructions: data.driverInstructions
        },
        isSendingConfirmationEmailUnChecked: !data.isConfirmationMailChecked,
        contact: {
          ...data.contact,
          backupPhone:
            isEmpty(data.contact?.backupPhone) && hasSiteContactNameAndPhone
              ? data.contact?.mainPhone
              : data.contact?.backupPhone
        }
      }
    }))

    methods.setValue('isOrderRequestConfirmed', false)

    setShowConfirmationStatusScreen(true)
    setDeliveries(updatedDeliveries)

    if (!isEmpty(data.driverInstructions)) {
      trackEvent('hubOrderIntakeInstructionAdded')
      const isCustomerInstructionsAddedRegex = new RegExp(/(\w|\s|\W)+Customer: (\w|\s|\W)+/g)
      if (isCustomerInstructionsAddedRegex.test(data.driverInstructions ?? '')) {
        trackEvent('hubOrderIntakeAdditionalDriverInstructionsAdded')
      }
    }

    updatedDeliveries.forEach((delivery) => {
      if (delivery.payload.deliveryDate !== delivery.initialDeliveryDate) {
        trackEvent('hubOrderIntakeDefaultDateNotUsed')
      }
      if (!isEqual(delivery.payload.deliveryTime, delivery.initialDeliveryTime)) {
        trackEvent('hubOrderIntakeDefaultTimeNotUsed')
      }
      if (delivery.payload.capacity.quantity !== delivery.initialTruckQuantity) {
        trackEvent('hubOrderIntakeDefaultQuantityNotUsed')
      }
      if (!isEqual(delivery.payload.contact?.fullName, delivery.initialFullName)) {
        trackEvent('hubOrderIntakeOrderPlacerNameChanged')
      }
    })
    trackEvent('hubOrderIntakeEnd', {
      shippingType: ShippingType.DELIVER,
      numberOfMaterials: uniq(
        updatedDeliveries.map((delivery) => delivery.payload.materialEnteredNumber)
      ).length,
      businessLine: defaultBusinessLine,
      quantityType,
      orderVolume: updatedDeliveries.reduce((result, delivery) => {
        return {
          ...result,
          [delivery.payload.materialEnteredNumber]:
            (result[delivery.payload.materialEnteredNumber] || 0) +
            delivery.payload.capacity.quantity
        }
      }, {}),
      numberOfOrderRequests: updatedDeliveries.length,
      purchaseOrderNumberVisible: orderIntakeCustomerReferenceField
    })
    if (
      updatedDeliveries[0].payload.contact?.mainPhone !==
      updatedDeliveries[0].payload.contact?.backupPhone
    )
      trackEvent('hubOrderIntakeAlternativePhoneNumberUsed')

    const isTwelveHours = isTwelveHoursFormat(language)

    orders.map((delivery) => {
      if (delivery.timeTrackingData) {
        delivery.timeTrackingData.map((userInteraction) => {
          trackEvent('hubOrderIntakeEditTime', {
            orderSessionEventId,
            cardId: userInteraction.cardId,
            slotManaged: userInteraction.slotManaged,
            startEditingTimeStamp: userInteraction.startEditingTimeStamp,
            applyChangesTimeStamp: userInteraction.applyChangesTimeStamp,
            events: userInteraction.events,
            earliestTimeFormat: isTwelveHours ? '12h' : '24h',
            latestTimeFormat: isTwelveHours ? '12h' : '24h'
          })
        })
      }
    })
  }

  const onCustomerReferenceChange = (index: number, customerReference: string) => {
    methods.setValue(`orders.${index}.payload.customerReference`, customerReference)
  }

  const onEditorActivation = (index: number) => {
    const orderPath = `orders.${index}` as const
    const order = methods.getValues(orderPath)

    const defaultMaterialOption = first(selectedSite.materials[order.payload.materialEnteredNumber])
    const timeZone = defaultMaterialOption?.businessHours.timeZone
    setEditorActivationTimeStamp(getCurrentTimeStamp(checkEmptyTimeZone(timeZone)))
  }

  const onClosePersistedMaterialsBanner = (action: PersistedMaterialsAction) => {
    switch (action) {
      case PersistedMaterialsAction.ApplyMaterials: {
        applyPersistedMaterials()
        trackPersistedMaterialsShortcutButton()
        notify('info', t('orderIntake.materialPersistency.confirmationMessage'), {
          autoHideDuration: 2000
        })
        return
      }
      case PersistedMaterialsAction.ShowMaterials: {
        setShowPersistedMaterialsDialog(true)
        return
      }
      default: {
        setShowPersistedMaterialsBanner(false)
      }
    }
  }

  const onClosePersistedMaterials = (apply: boolean) => {
    setShowPersistedMaterialsDialog(false)

    if (apply) {
      applyPersistedMaterials()
    }
  }

  const applyPersistedMaterials = () => {
    const orderRequestBySiteNumber = orders.find((or) => or.payload.siteNumber === siteNumber)

    const validPersistedOrderRequests = persistedOrderRequests?.filter((persistedItem) =>
      validMaterialsForPersistency.some(
        (item) => item.material.materialEnteredNumber === persistedItem.materialEnteredNumber
      )
    )

    const orderRequests = mergePersistedOrders(
      orderRequestBySiteNumber ? orderRequestBySiteNumber : (defaultDelivery as OrderRequest),
      selectedSite,
      validPersistedOrderRequests
    )

    if (orderRequests) {
      replaceOrders(
        orderRequests.map((orderRequest) => ({
          ...orderRequest,
          payload: {
            ...orderRequest.payload,
            customerReference: orderRequest.payload.customerReference || DEFAULT_CUSTOMER_REFERENCE
          }
        }))
      )
    }

    trackEvent('hubOrderIntakeReviewPersistedMaterials', {
      user_uuid: userId,
      country: userProfileCountry,
      primaryOrganisationalUnit: userPrimaryOrganisationalUnit,
      customerId: customerId,
      siteId: siteNumber,
      shippingType: ShippingType.DELIVER,
      businessLine: defaultBusinessLine
    })
  }

  const trackPersistedMaterialsShortcutButton = () => {
    trackEvent('hubOrderIntakeShortcutCopyPersistedMaterials', {
      user_uuid: userId,
      country: userProfileCountry,
      primaryOrganisationalUnit: userPrimaryOrganisationalUnit,
      customerId: customerId,
      siteId: siteNumber,
      shippingType: ShippingType.DELIVER,
      businessLine: defaultBusinessLine
    })
  }

  const isCustomerReferenceSame = areAllCustomerReferencesSame(orders)
  const validMaterialOptions = getMaterialOptionsByInvalidity(selectedSite.materials, false)
  const invalidMaterialOptions = getMaterialOptionsByInvalidity(selectedSite.materials, true)
  const noValidMaterial =
    (invalidMaterialOptions.length >= 1 && isEmpty(validMaterialOptions)) ||
    isInvalidOrderIntakeOption(defaultDelivery)
  const selectedMaterialIndex = orderFields.findIndex(
    (d) => d.id === materialEditorController.activeMaterialForm
  )
  // we cannot have form in form, if so, it won't work and submit button will trigger upper form
  // so when there is activeMaterialForm, we disable the form and render only div
  const isMainFormActive = !materialEditorController.activeMaterialForm
  const Form = !materialEditorController.activeMaterialForm ? 'form' : 'div'
  return (
    <FormProvider {...methods}>
      <Form onSubmit={isMainFormActive ? methods.handleSubmit(onSubmit) : undefined}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={4}>
              <Grid container justifyContent="space-between">
                {showConfirmationStatusScreen && (
                  <Modal open={true} onClose={handleClose}>
                    <DeliveryOrderIntakeRequestLegacyConfirmation
                      businessLine={defaultBusinessLine}
                      onClose={handleClose}
                    />
                  </Modal>
                )}

                {isMaterialPersistency &&
                  persistedOrderRequests &&
                  showPersistedMaterialsDialog && (
                    <Dialog open={true} onClose={onClosePersistedMaterials}>
                      <DialogContent>
                        <PersistedMaterialsDialog
                          persistedMaterials={persistedOrderRequests}
                          validMaterials={validMaterialsForPersistency}
                          existsValidMaterials={existsValidPersistedMaterials}
                          onClose={onClosePersistedMaterials}
                        />
                      </DialogContent>
                    </Dialog>
                  )}

                <Grid
                  container
                  className={classes.container}
                  justifyContent="space-between"
                  data-test-id="order-intake-form-container"
                >
                  <Grid container spacing={4}>
                    <Hidden smDown={!!activePopup}>
                      <Grid item xs={12} sm={8}>
                        <SectionHeader
                          data-test-id="delivery-site-selection-section-header"
                          title={t('orderIntake.deliveryAddress')}
                          caption={t('orderIntake.lastDestinationShown')}
                          first
                        />
                        <Grid item xs={12} sm={8}>
                          <Controller
                            control={methods.control}
                            name="selectedSite"
                            render={({field}) => (
                              <SiteDropdown
                                onChange={partialRight(
                                  formChangeHandlers.onSiteChange,
                                  deliverOptions,
                                  defaultDelivery
                                )}
                                selectedSite={field.value}
                                shippingType={ShippingType.DELIVER}
                                options={siteOptions}
                                disabled={!isMainFormActive}
                                fetchOrderIntakeOptions={() =>
                                  invalidateHubQuery('orderRequestsOptions', {
                                    customerId,
                                    applyMaterialEnteredDescription,
                                    applyContractItemDescription
                                  })
                                }
                              />
                            )}
                          />
                        </Grid>

                        {isMaterialPersistency &&
                          persistedOrderRequests &&
                          showPersistedMaterialsBanner &&
                          !noValidMaterial && (
                            <PersistedMaterialsBanner
                              onClose={onClosePersistedMaterialsBanner}
                              existsValidMaterials={existsValidPersistedMaterials}
                            />
                          )}

                        {!noValidMaterial ? (
                          isOrderRequestsCacheLoading ? (
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              marginTop={10}
                            >
                              <img
                                src={distributedCacheLoadingImage}
                                data-test-id="order-intake-loading-data-from-cache"
                                style={{textAlign: 'center', maxWidth: 350}}
                                alt={t('orderIntake.loadingDataFromCache')}
                              />
                              <Box marginTop={2}>
                                <Typography
                                  variant="body2"
                                  color="secondary"
                                  style={{textAlign: 'center', maxWidth: 350}}
                                >
                                  {t('orderIntake.loadingDataFromCache')}
                                </Typography>
                              </Box>
                            </Box>
                          ) : (
                            <>
                              <Stack spacing={2}>
                                <SectionHeader
                                  data-test-id="delivery-material-selection-section-header"
                                  title={t('orderIntake.cementTypeDelivery')}
                                  caption={t('orderIntake.startSelectionFromLastOrder')}
                                />
                                {orderFields.map((delivery, index) => {
                                  const defaultMaterialOption = getDefaultMaterialOptions(
                                    selectedSite.materials,
                                    delivery.payload
                                  )
                                  const {status, isSuccess} =
                                    materialEditorController.getMaterialFormProps(delivery.id)
                                  if (!defaultMaterialOption) return null
                                  return (
                                    <DeliverMaterialLegacyForm
                                      key={delivery.id}
                                      defaultMaterialOption={defaultMaterialOption}
                                      isCustomerReferenceSame={isCustomerReferenceSame}
                                      orderIndex={index}
                                      removeDelivery={() => removeDelivery(index)}
                                      isSuccess={isSuccess}
                                      status={status}
                                      checkValidityBySlots={
                                        isSlotsManagementEnabled && useConfigurableSlots
                                      }
                                      slotConfiguration={slotConfiguration}
                                      onActivate={(item) => {
                                        materialEditorController.setActiveOrderBox(
                                          delivery.id,
                                          item
                                        )
                                        onEditorActivation(index)
                                      }}
                                    />
                                  )
                                })}

                                <AddAnotherLegacyButton
                                  businessLine={defaultBusinessLine}
                                  disabled={
                                    materialEditorController.activeMaterialForm !== undefined ||
                                    isOrderRequestsCacheLoading
                                  }
                                  onClick={addDelivery}
                                />
                              </Stack>
                              <ContactLegacySection
                                shippingType={ShippingType.DELIVER}
                                dataTestPrefix="delivery"
                              />
                            </>
                          )
                        ) : null}
                        {noValidMaterial ? (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            marginTop={10}
                          >
                            <img
                              src={noValidMaterialImage}
                              data-test-id="order-intake-no-valid-ship-to-image"
                              style={{textAlign: 'center', maxWidth: 350}}
                              alt={t(
                                'orderIntake.invalidMaterials.deliveryNoPossibleForDestination'
                              )}
                            />
                            <Box marginTop={2}>
                              <Typography
                                variant="body2"
                                color="secondary"
                                style={{textAlign: 'center', maxWidth: 350}}
                              >
                                {t(
                                  'orderIntake.invalidMaterials.deliveryNoPossibleForDestinationMessage'
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        ) : null}
                      </Grid>
                    </Hidden>
                    <Hidden smDown={!activePopup}>
                      <Grid item xs={12} sm={4}>
                        <div className={classes.stickyContainer}>
                          {materialEditorController.activeMaterialForm === undefined ? (
                            <OrderSummaryLegacySection
                              shippingType={ShippingType.DELIVER}
                              customerId={customerId}
                              quantityType={quantityType}
                              onCloseSummaryBox={() => setActivePopup(undefined)}
                              onCustomerRefChange={formChangeHandlers.onGlobalCustomerRefChange}
                              onEmailCcChange={formChangeHandlers.onGlobalEmailCcChange}
                            />
                          ) : (
                            <MaterialOrderLegacyEditor
                              key={materialEditorController.activeMaterialForm}
                              index={selectedMaterialIndex}
                              shippingType={ShippingType.DELIVER}
                              activeItem={materialEditorController.activeSection}
                              showPoTab={!isCustomerReferenceSame}
                              closeActiveItem={() =>
                                materialEditorController.setActiveOrderBox(undefined)
                              }
                              onChange={() =>
                                materialEditorController.activeMaterialForm &&
                                materialEditorController.notifySuccessfullySaved(
                                  materialEditorController.activeMaterialForm
                                )
                              }
                              onDateChange={partial(
                                formChangeHandlers.onDateChange,
                                selectedMaterialIndex
                              )}
                              onTimeChange={partial(
                                formChangeHandlers.onTimeChange,
                                selectedMaterialIndex
                              )}
                              onCustomerReferenceChange={(customerReference) =>
                                onCustomerReferenceChange(selectedMaterialIndex, customerReference)
                              }
                              onTimeSelectorTrack={partial(
                                formChangeHandlers.onTimeSelectorTrackApply,
                                selectedMaterialIndex,
                                editorActivationTimeStamp,
                                isSlotsManagementEnabled,
                                orderFields[selectedMaterialIndex].id
                              )}
                            />
                          )}
                        </div>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Hidden smUp smDown={!!activePopup}>
            {!noValidMaterial ? (
              <ShowOrderSummaryLegacySection
                onOpenOrderSummary={() => setActivePopup('OrderSummary')}
                disabled={
                  !methods.formState.isValid || noValidMaterial || isOrderRequestsCacheLoading
                }
              />
            ) : (
              <Grid item xs={12}>
                <Card>
                  <SummaryNoValidMaterials invalidMaterialOptions={invalidMaterialOptions} />
                </Card>
              </Grid>
            )}
          </Hidden>
        </Grid>
      </Form>
    </FormProvider>
  )
}
