import {HCThemeType} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core'
import {Box} from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {OptionSelectInputChangeType, TimeFormatValues} from '../../declarations/types'
import {InputWithControls} from '../Forms'
import {
  getTimeFormatValue,
  timeFormatter,
  TWENTY_FOUR_HOUR_FORMAT
} from '../TimeScroller/TimeScroller.utils'

type IncrementState = {
  index: number
  nextIndex?: number
  previousIndex?: number
}

const getDefaultIncrementState = (options: string[], value: string): IncrementState => {
  const index = options.indexOf(value)
  if (index === -1) return {index}
  const previousIndex = index < 1 ? undefined : index - 1
  const nextIndex = index >= options.length - 1 ? undefined : index + 1
  return {index, previousIndex, nextIndex}
}

export const useTimeSelectTimeFormatSwitcher = makeStyles((theme: HCThemeType) => {
  const backgroundColor = theme.palette.grey[50]

  return {
    timeFormatButton: {
      cursor: 'pointer',
      fontSize: 18,
      fontWeight: 'bolder',
      backgroundColor,
      boxSizing: 'content-box',
      position: 'relative',
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 4,
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
      maxHeight: '60px',
      marginLeft: '20px'
    },
    showError: {
      border: '1px solid red'
    }
  }
})

type Props = {
  name: string
  title?: string
  mask?: string
  value?: string
  options?: string[]
  transformOption?: (value: string) => string // this is hook when user types in input
  getIncrementState?: (currentValue: string, incrementState: IncrementState) => IncrementState
  onChange?: (value: string) => void
  onTrackChange?: (changeType: OptionSelectInputChangeType) => void
  showError?: boolean
  highlight?: boolean
  isTwelveHours: boolean
  'data-test-id'?: string
}

export const OptionSelectInput: React.FC<Props> = ({
  title,
  name,
  value = '',
  options = [],
  transformOption: transformOption,
  getIncrementState: getIncrementState,
  onChange,
  onTrackChange,
  showError,
  highlight,
  isTwelveHours,
  'data-test-id': dataTestId = 'OptionSelectInput'
}) => {
  const {
    i18n: {language}
  } = useTranslation()

  const c = useTimeSelectTimeFormatSwitcher()

  const inputRef = useRef<HTMLInputElement>(null)
  const [internalValue, setInternalValue] = useState(value)
  const [previousValue, setPreviousValue] = useState(value)
  const [mapListOptions, setMapListOptions] = useState<string[]>(options)

  const incrementState = getDefaultIncrementState(options, internalValue)
  const {index, nextIndex, previousIndex}: IncrementState = getIncrementState
    ? getIncrementState(internalValue, incrementState)
    : incrementState

  const [currentTimeFormat, setCurrentTimeFormat] = useState<TimeFormatValues | null>(
    getTimeFormatValue(value, language)
  )

  useEffect(() => {
    if (index > -1 && internalValue !== value) {
      onChange && onChange(internalValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalValue])

  useEffect(() => {
    setInternalValue(value)
    setCurrentTimeFormat(getTimeFormatValue(value, language))
  }, [value])

  useEffect(() => {
    setMapListOptions(isTwelveHours ? formatOptions(options) : options)
  }, [options])

  const formatOptions = (originalOptions: string[]) => {
    const formattedOptions: string[] = []

    originalOptions.forEach((item) => {
      const formattedTime = timeFormatter(moment(item, 'HH:mm:ss'), language)
      const newOption = formattedTime.replace('AM', '').replace('PM', '').replace(' ', '')
      if (formattedOptions.indexOf(newOption) === -1) formattedOptions.push(newOption)
    })

    return formattedOptions
  }

  const setInternalValueByIndex = (nextIndex) => {
    const nextValue = options[nextIndex]
    if (!nextValue) return
    setInternalValue(nextValue)
  }

  const setInternalValueByTimeFormat = (newTimeFormat: TimeFormatValues) => {
    const timeFormatValue = getTimeFormatValue(internalValue, language)

    if (timeFormatValue) {
      if (timeFormatValue === TimeFormatValues.AM && newTimeFormat === TimeFormatValues.PM) {
        const newValue = moment(internalValue, TWENTY_FOUR_HOUR_FORMAT)
          .add(12, 'hours')
          .format(TWENTY_FOUR_HOUR_FORMAT)
        setInternalValue(newValue)
        onChange && onChange(newValue)
      }

      if (timeFormatValue === TimeFormatValues.PM && newTimeFormat === TimeFormatValues.AM) {
        const newValue = moment(internalValue, TWENTY_FOUR_HOUR_FORMAT)
          .subtract(12, 'hours')
          .format(TWENTY_FOUR_HOUR_FORMAT)
        setInternalValue(newValue)
        onChange && onChange(newValue)
      }
    }
  }

  const onFocusField: React.FocusEventHandler<HTMLInputElement> = (e) => {
    if (!transformOption) return
    const newValue = transformOption(e.target.value)
    setPreviousValue(newValue)

    if (isTwelveHours) setInternalValue(e.target.value)
  }

  const onBlurField: React.FocusEventHandler<HTMLInputElement> = (e) => {
    if (!transformOption) return

    let resolvedOriginalValue = e.target.value

    if (isTwelveHours && currentTimeFormat === TimeFormatValues.PM) {
      resolvedOriginalValue = moment(e.target.value, TWENTY_FOUR_HOUR_FORMAT)
        .add(12, 'hours')
        .format(TWENTY_FOUR_HOUR_FORMAT)
    }

    const newValue = transformOption(resolvedOriginalValue)

    setInternalValue(newValue)
    if (previousValue !== newValue) onTrackChange && onTrackChange('inputField')
  }

  const onChangeField: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!isTwelveHours) setInternalValue(e.target.value)
  }

  const keydownEventHandler: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if ('0123456789:'.indexOf(e.key) === -1) e.preventDefault()
  }

  const changeTimeFormat = () => {
    const newTimeFormat =
      currentTimeFormat === TimeFormatValues.AM ? TimeFormatValues.PM : TimeFormatValues.AM
    setCurrentTimeFormat(newTimeFormat)
    setInternalValueByTimeFormat(newTimeFormat)
  }

  return (
    <Box display="flex">
      <InputWithControls
        data-test-id={dataTestId}
        onDecrement={() => {
          setInternalValueByIndex(previousIndex)
          onTrackChange && onTrackChange('decrease')
        }}
        onIncrement={() => {
          setInternalValueByIndex(nextIndex)
          onTrackChange && onTrackChange('increase')
        }}
        decrementProps={{disabled: previousIndex === undefined}}
        incrementProps={{disabled: nextIndex === undefined}}
        title={title}
        name={name}
        value={internalValue}
        canShowTimeFormat={true}
        showError={showError}
        highlight={highlight}
        onChange={(e) => onChangeField(e)}
        inputProps={{
          list: `${name}-datalist`,
          maxLength: 5,
          autoComplete: 'off',
          onKeyDown: keydownEventHandler,
          onBlur: onBlurField,
          onFocus: onFocusField
        }}
        ref={inputRef}
      >
        <datalist id={`${name}-datalist`}>
          {mapListOptions
            .filter((_, i) => i !== index)
            .map((o) => (
              <option key={o} value={o} />
            ))}
        </datalist>
      </InputWithControls>

      {isTwelveHours && (
        <Box
          className={classNames(c.timeFormatButton, showError ? c.showError : '')}
          data-test-id={`${dataTestId}-am-pm-switcher`}
          onClick={(e) => {
            e.preventDefault()
            changeTimeFormat()
          }}
        >
          {currentTimeFormat}
        </Box>
      )}
    </Box>
  )
}
