/* eslint-disable complexity */
import * as crypto from 'crypto'

import {
  BorderedTextField,
  Typography,
  SingleDatePicker,
  MOMENTJS_DATE_FORMAT,
  SectionHeader,
  DateRange,
  useNotification,
  PhoneNumberTextField,
  SelectDropdown
} from '@hconnect/uikit'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  Link,
  Switch,
  Tooltip
} from '@material-ui/core'
import {Add, AttachFileOutlined, Close} from '@material-ui/icons'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import InfoIcon from '@material-ui/icons/Info'
import {CircularProgress, Grid, IconButton, Tab, Tabs, Paper} from '@mui/material'
import {AxiosError} from 'axios'
import classNames from 'classnames'
import {CountryCode, isValidPhoneNumber} from 'libphonenumber-js'
import {isEmpty} from 'lodash'
import moment from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import {useForm, useFieldArray, FormProvider, Controller, SubmitHandler} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {v4 as uuidv4} from 'uuid'

import {usePostRequestForQuote} from '../../../../src/common/react-query/hooks/mutations/usePostRequestForQuote'
import {useGetRequestForQuotesRegions} from '../../../../src/common/react-query/hooks/queries/useGetRequestForQuotesRegions'
import {selectCustomers} from '../../../../src/Organisms/Customers'
import {CustomerStateType} from '../../../../src/Organisms/Customers/Action.types'
import CustomersFilter from '../../../../src/Organisms/Customers/CustomersFilter'
import {Features, useFeaturesState} from '../../../../src/Organisms/Features'
import DownloadPDFPNG from '../../../Assets/pdf-download.png'
import {trackEvent} from '../../../common/analytics'
import {AppState} from '../../../Root.store'
import {GoogleMapWithSearchbox} from '../../components/GoogleMapWithSearchBox'
import {LabelValueCodeType, LabelValueType, SalesOfficeData} from '../../declarations/types'
import {useOrderIntakeData} from '../../hooks'
import {
  PersistedRequestForQuoteFormValues,
  RequestForQuoteAttachment,
  RequestForQuoteFormAddressAvailabilityType,
  RequestForQuoteFormStatusType,
  RequestForQuoteFormValues,
  RequestForQuoteMaterial,
  RequestForQuoteResponse
} from '../../RequestForQuoteFormValues'

import CheckboxGroup from './CheckBoxGroup'
import {RequestForQuoteFormConfirmation} from './RequestForQuoteFormConfirmation'
import {
  BAGGED_MATERIALS_BUSINESS_LINE_UNITS,
  OTHER_BUSINESS_LINE_UNITS,
  RequestForQuoteMaterialForm
} from './RequestForQuoteMaterialForm'
import {useRequestForQuoteStyles} from './useRequestForQuoteStyles'
import {usePersistRequestForQuote} from '../../../../src/common/react-query/hooks/mutations/usePersistRequestForQuote'
import {usePersistedRequestForQuote} from '../../../../src/common/react-query/hooks/queries/usePersistedRequestForQuote'
import {
  PersistedRequestForQuoteAction,
  PersistedRequestForQuoteBanner
} from '../../components/PersistedRequestForQuote/PersistedRequestForQuoteBanner'

import {useGetRolesCountWithOrgUnitId} from '../../../../src/Roles'
import {
  getBusinessLines,
  getCountries,
  getProjectTypes,
  getServices,
  servicesConfig
} from './RfqData'
import {removeDuplicatesObjects} from '../utils'

const FILE_MAX_SIZE_IN_BYTES = 5242880
const FILE_MAX_SIZE = '5MB'
const FILE_MAX_NAME_LENGTH = 200
const SMART_ROCK_MIN_AVAILABILITY = 100

const SUPPORTED_FILE_TYPES = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/excel'
]
const PDF_FILE_TYPE = ['application/pdf']
const mqpOrgUnit = 'GB37'

export const RequestForQuoteForm = () => {
  const c = useRequestForQuoteStyles()
  const {t} = useTranslation()

  const {notify} = useNotification()
  const {mutate} = usePostRequestForQuote()
  const {mutate: persistRequestForQuote} = usePersistRequestForQuote()

  const {
    userProfileCountry: countryCode,
    userEmail,
    userCompany,
    userMarketId
  } = useOrderIntakeData()
  const {getFeature} = useFeaturesState()
  const [totalRolesCount, totalMqpRoles] = useGetRolesCountWithOrgUnitId(mqpOrgUnit)

  const isSmartRockEnabled = getFeature('OrderIntakeRequestForQuoteSmartrockLeadGen')
  const uploadOnlyPdfFiles = getFeature('OrderIntakeRequestForQuoteUploadOnlyPdfFile')
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [attachedFile, setAttachedFile] = useState<RequestForQuoteAttachment>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showSupplier, setShowSupplier] = useState<boolean>(false)
  const [supplierOptions, setSupplierOptions] = useState<string[]>([])
  const [selectedSupplier, setSelectedSupplier] = useState<string>()

  const customersState = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  const {customers, selectedCustomer, isFetching: isCustomersFetching} = customersState
  const MAX_CHARS = 255
  const DEFAULT_UNIT = 'tons'
  const DEFAULT_UNIT_BAGGED_MATERIALS = 'bags'
  const DEFAULT_UNIT_RMC = 'm3'

  const isOnlyMqpRole = totalRolesCount > 0 && totalRolesCount === totalMqpRoles
  const isOnlyNonMqpRole = totalRolesCount > 0 && totalMqpRoles === 0

  const smartRockExternalLink =
    'https://www.hanson.co.uk/en/ready-mixed-concrete/smartrock-wireless-maturity-sensors'

  const [selectedProjectType, setSelectedProjectType] = useState<LabelValueType>()
  const [selectedBusinessLine, setSelectedBusinessLine] = useState<LabelValueCodeType>()
  const [selectedRegion, setSelectedRegion] = useState<SalesOfficeData>()
  const [selectedPlant, setSelectedPlant] = useState<SalesOfficeData>()
  const [isVisibleRegion, setIsVisibleRegion] = useState<boolean>(true)
  const [isVisiblePlant, setIsVisiblePlant] = useState<boolean>(false)
  const [isRMCBusinessLine, setIsRMCBusinessLine] = useState<boolean>(false)
  const [isBaggedMaterialsBusinessLine, setIsBaggedMaterialsBusinessLine] = useState<boolean>(false)
  const [enabledSmartRock, setEnabledSmartRock] = useState<boolean>(false)
  const [requestSmartRock, setRequestSmartRock] = useState<boolean>(true)
  const [addressAvailabilityValue, setAddressAvailabilityValue] = React.useState(0)

  const [showPersistedRequestForQuoteBanner, setShowPersistedRequestForQuoteBanner] =
    useState<boolean>(true)

  const [isApplyingPersistedRequestForQuote, setIsApplyingPersistedRequestForQuote] =
    useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const countries = getCountries(t)
  const services = getServices(t)
  const businessLines = getBusinessLines(isOnlyMqpRole, t)

  const defaultRfqCountryCode = countries
    .map((country) => country.value)
    .includes(countryCode ?? '')
    ? countryCode
    : ''
  const defaultSelectedRfqCountry = countries.find(
    (country) => country.value === defaultRfqCountryCode
  ) ?? {label: '', value: ''}

  const [selectedCountry, setSelectedCountry] = useState<LabelValueType | undefined>(
    defaultSelectedRfqCountry
  )

  const projectTypes = getProjectTypes(t, selectedCountry ?? defaultSelectedRfqCountry)

  const {data: regions, isLoading: isRegionsLoading} = useGetRequestForQuotesRegions(
    selectedCountry?.value ?? ''
  )

  const [regionsByBusinessLine, setRegionsByBusinessLine] = useState<SalesOfficeData[]>([])
  const [plantsByRegions, setPlantsByRegions] = useState<SalesOfficeData[]>([])

  const {data: persistedRequestForQuote} = usePersistedRequestForQuote(
    selectedCustomer?.customerNumber ?? '',
    selectedBusinessLine?.value ?? ''
  )

  const [availableDateRangeFrom, setAvailableDateRangeFrom] = useState<DateRange>({
    from: moment().subtract(100, 'years'),
    to: moment().add(100, 'years')
  })

  const [availableDateRangeTo, setAvailableDateRangeTo] = useState<DateRange>({
    from: moment().add(1, 'days'),
    to: moment().add(1, 'years')
  })

  const getBusinessLinesForSelectedCountry = () => {
    if (regions) {
      const regionalBusinessLines = regions.map((item) =>
        item.productCategoryCode ? item.productCategoryCode : ''
      )
      const uniqueBusinessLines = [...new Set(regionalBusinessLines)]
      const result = businessLines.filter((line) => uniqueBusinessLines.includes(line.code))
      return result
    }
    return []
  }

  const getDefaultUnit = () => {
    if (isBaggedMaterialsBusinessLine) return DEFAULT_UNIT_BAGGED_MATERIALS

    if (isRMCBusinessLine) return DEFAULT_UNIT_RMC

    return DEFAULT_UNIT
  }

  const methods = useForm<RequestForQuoteFormValues>({
    mode: 'all',
    defaultValues: {
      customerName: customers[0]?.customerName ?? '',
      customerNumber: customers[0]?.customerNumber ?? '',
      projectDetails: {
        projectStatus: RequestForQuoteFormStatusType.Tender
      }
    }
  })

  const {
    append: appendMaterial,
    remove: deleteMaterial,
    replace: replaceMaterials
  } = useFieldArray({
    control: methods.control,
    name: 'materials'
  })

  const materials = methods.watch('materials')

  const addMaterial = () => {
    appendMaterial(
      {
        id: uuidv4(),
        name: '',
        quantity: {unitOfMeasure: getDefaultUnit()},
        isWithoutAdditives: false
      },
      {
        shouldFocus: false
      }
    )
  }

  const removeMaterial = (index: number) => {
    deleteMaterial(index)
  }

  const [confirmationStatusScreen, setConfirmationStatusScreen] = useState({
    show: false,
    isError: false,
    errorMessages: [] as string[]
  })

  const isNamRegion = ['US', 'CA'].includes(selectedCountry?.value ?? '')

  const validateInput = (inputValue: string) => {
    const validInputPattern = /["\t\n]/g

    const found = inputValue && inputValue.match(validInputPattern)
    if (found && found.length > 0) return false

    return true
  }

  const validateMultilineInput = (inputValue: string) => {
    const validInputPattern = /["\t]/g

    const found = inputValue && inputValue.match(validInputPattern)
    if (found && found.length > 0) return false

    return true
  }

  const removeInvalidCharacters = (inputValue: string) => {
    return inputValue.replace(/\t+/g, ' ').replace(/\s{2,}/g, ' ')
  }

  const handleClose = (resetForm: boolean) => {
    setConfirmationStatusScreen({show: false, isError: false, errorMessages: []})

    if (resetForm) {
      methods.resetField('materials')
      methods.reset()

      setAvailableDateRangeFrom({
        from: moment().subtract(100, 'years'),
        to: moment().add(100, 'years')
      })

      setAvailableDateRangeTo({
        from: moment().add(1, 'days'),
        to: moment().add(1, 'years')
      })

      setSelectedProjectType(undefined)
      setAttachedFile(undefined)
      setSelectedBusinessLine(undefined)
      setRequestSmartRock(true)
      setEnabledSmartRock(false)
      setSelectedRegion(undefined)
      setSelectedPlant(undefined)
      setShowPersistedRequestForQuoteBanner(true)
      setShowSupplier(false)
      setSelectedSupplier(undefined)
      setIsVisibleRegion(true)
      setRegionsByBusinessLine([])

      addMaterial()
    }
  }

  const checkBussinessLine = () => {
    const isRMC = selectedBusinessLine?.value === 'ReadyMixedConcrete'
    const isBaggedMaterial = selectedBusinessLine?.value === 'BaggedMaterial'
    let totalMaterialQuantity = 0
    materials && materials.forEach((a) => (totalMaterialQuantity += a.quantity?.amount ?? 0))

    const showSupplierAttribute =
      totalRolesCount > 0 &&
      totalMqpRoles > 0 &&
      totalRolesCount > totalMqpRoles &&
      (selectedBusinessLine?.value === 'Aggregates' || selectedBusinessLine?.value === 'Asphalt')

    if (showSupplierAttribute) {
      const filteredRegions = getFilteredRegions()
      let distinctSuppliers = filteredRegions?.map((item) => item.companyName) ?? []
      distinctSuppliers = distinctSuppliers.filter((v, i, a) => a.indexOf(v) === i) ?? []
      setSupplierOptions(distinctSuppliers)
    }

    setShowSupplier(showSupplierAttribute)

    setIsRMCBusinessLine(isRMC)
    setIsBaggedMaterialsBusinessLine(isBaggedMaterial)

    setEnabledSmartRock(isRMC && totalMaterialQuantity >= SMART_ROCK_MIN_AVAILABILITY)

    checkMaterialDefaultUnitOfMeassure(isRMC, isBaggedMaterial)
  }

  const checkMaterialDefaultUnitOfMeassure = (isRMC: boolean, isBaggedMaterial: boolean) => {
    const resolvedMaterials: RequestForQuoteMaterial[] = []
    let changedUnitOfMeasure = false

    materials &&
      materials.forEach((material) => {
        const resolvedMaterial = {...material}

        if (isRMC && material.quantity.unitOfMeasure !== DEFAULT_UNIT_RMC) {
          resolvedMaterial.quantity.unitOfMeasure = DEFAULT_UNIT_RMC
          changedUnitOfMeasure = true
        }
        if (
          isBaggedMaterial &&
          !isRMC &&
          !BAGGED_MATERIALS_BUSINESS_LINE_UNITS.includes(material.quantity.unitOfMeasure)
        ) {
          resolvedMaterial.quantity.unitOfMeasure = DEFAULT_UNIT_BAGGED_MATERIALS
          changedUnitOfMeasure = true
        }
        if (
          !isBaggedMaterial &&
          !isRMC &&
          !OTHER_BUSINESS_LINE_UNITS.includes(material.quantity.unitOfMeasure)
        ) {
          resolvedMaterial.quantity.unitOfMeasure = DEFAULT_UNIT
          changedUnitOfMeasure = true
        }

        resolvedMaterials.push(resolvedMaterial)
      })

    if (changedUnitOfMeasure)
      methods.setValue('materials', resolvedMaterials, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      })
  }

  const getFilteredRegions = () => {
    return regions?.filter((item) => item.productCategoryCode === selectedBusinessLine?.code)
  }

  const filterRegions = () => {
    const filteredRegions = getFilteredRegions()
    let uniqueRegions = removeDuplicateRegions(filteredRegions ?? [])

    if (isOnlyMqpRole) {
      uniqueRegions = uniqueRegions?.filter((item) => item.salesOrganization === mqpOrgUnit)
    }

    if (isOnlyNonMqpRole) {
      uniqueRegions = uniqueRegions?.filter((item) => item.salesOrganization !== mqpOrgUnit)
    }

    if (showSupplier && selectedSupplier) {
      uniqueRegions = filterRegionsBySupplier(uniqueRegions ?? [], selectedSupplier)
    }

    setRegionsByBusinessLine(uniqueRegions ?? [])
  }

  const filterRegionsBySupplier = (regions: SalesOfficeData[], supplier: string) => {
    return regions.filter((item) => item.companyName === supplier)
  }

  const removeDuplicateRegions = (arr: SalesOfficeData[]) => {
    const result = arr.reduce((acc: SalesOfficeData[], current: SalesOfficeData) => {
      if (!acc.some((item: SalesOfficeData) => item.region === current.region)) {
        acc.push(current)
      }
      return acc
    }, [])
    return result
  }

  const getSelectedRegionOffices = (selectedRegion: SalesOfficeData) => {
    const filteredRegions = getFilteredRegions()
    return filteredRegions?.filter((item) => item.region === selectedRegion.region) ?? []
  }

  useEffect(() => {
    if (selectedRegion) {
      const plants = getSelectedRegionOffices(selectedRegion)
      const uniquePlants = removeDuplicatesObjects(plants)
      setPlantsByRegions(uniquePlants)

      if (plants.length === 1) {
        const plant = plants[0]
        setSelectedPlant(plant)
        setIsVisiblePlant(false)
        methods.setValue('salesOfficeEmail', plant.salesOfficeEmail, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true
        })
      } else {
        setSelectedPlant(undefined)
        setIsVisibleRegion(true)
        setIsVisiblePlant(true)
      }
    } else {
      setSelectedPlant(undefined)
    }
  }, [selectedRegion])

  useEffect(() => {
    if (regionsByBusinessLine.length === 1) {
      if (plantsByRegions.length > 1) {
        setIsVisibleRegion(true)
        setIsVisiblePlant(true)
      }
      if (plantsByRegions.length === 1 && plantsByRegions[0].plant === '') {
        setIsVisiblePlant(false)
      }
    }
  }, [plantsByRegions])

  useEffect(() => {
    if (materials === undefined) {
      addMaterial()
    }
  }, [])

  useEffect(() => {
    setSelectedSupplier(undefined)
    setSelectedRegion(undefined)
    setSelectedPlant(undefined)
    setIsVisiblePlant(false)
    filterRegions()
    checkBussinessLine()
  }, [selectedBusinessLine])

  useEffect(() => {
    !isApplyingPersistedRequestForQuote && filterRegions()
  }, [selectedSupplier])

  useEffect(() => {
    if (isApplyingPersistedRequestForQuote) return

    if (regionsByBusinessLine.length === 1) {
      setSelectedRegion(regionsByBusinessLine[0])
      setIsVisibleRegion(false)
      methods.setValue('salesOfficeEmail', regionsByBusinessLine[0].salesOfficeEmail, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true
      })
    } else {
      setSelectedRegion(undefined)
      setIsVisibleRegion(true)
      methods.resetField('salesOfficeEmail')
    }
  }, [regionsByBusinessLine])

  useEffect(() => {
    checkBussinessLine()
  }, [materials])

  useEffect(() => {
    isApplyingPersistedRequestForQuote && applyPersistedRequestForQuote()
  }, [isApplyingPersistedRequestForQuote])

  const onSubmit: SubmitHandler<RequestForQuoteFormValues> = (data) => {
    if (selectedCustomer) {
      setIsLoading(true)

      const payload = {...data}
      payload.customerName = selectedCustomer?.customerName ?? ''
      payload.customerNumber = selectedCustomer?.customerNumber ?? ''
      payload.attachment = attachedFile
      payload.isSmartRockEnabled = enabledSmartRock && requestSmartRock
      payload.qcTechOfficeEmail =
        enabledSmartRock && requestSmartRock ? selectedRegion?.qcTechOfficeEmail : undefined
      payload.selectedRegion = selectedRegion
      payload.selectedSupplier = selectedSupplier
      payload.selectedCountry = selectedCountry?.value
      payload.plant = selectedPlant?.plant

      const persistedData = {} as PersistedRequestForQuoteFormValues
      persistedData.customerName = payload.customerName
      persistedData.customerNumber = payload.customerNumber
      persistedData.businessLine = payload.businessLine
      persistedData.projectType = payload.projectType
      persistedData.phoneNumber = payload.phoneNumber
      persistedData.materials = payload.materials
      persistedData.selectedRegion = payload.selectedRegion
      persistedData.selectedSupplier = payload.selectedSupplier
      persistedData.services = payload.services

      persistRequestForQuote({
        siteNumber: payload.customerNumber,
        businessLine: payload.businessLine,
        formValues: persistedData
      })

      mutate(
        {payload: payload},
        {
          onSuccess: () => {
            setConfirmationStatusScreen({show: true, isError: false, errorMessages: []})
            setIsLoading(false)
            trackEvent('hubOrderIntakeRMCQuoteRequestWritten', {
              selectedCountry: selectedCountry?.value,
              selectedPlant: selectedPlant?.plant
            })
            payload.attachment && trackEvent('hubOrderIntakeRequestForQuotesFileUpload')
          },
          onError: (error: any) => {
            const errorData = (error as AxiosError).response?.data as RequestForQuoteResponse
            const errorCodes = errorData?.detail
              .split(',')
              .filter((item) => item.startsWith('RequestForQuotesDtoValidator_ErrorCode'))
            const errorMessages = errorCodes.map((errorCode) =>
              t(`orderIntake.requestForQuote.errors.${errorCode}`)
            )
            errorMessages.length > 0 &&
              trackEvent('hubOrderIntakeRequestForQuotesFileUploadError', {errorCodes: errorCodes})

            setConfirmationStatusScreen({show: true, isError: true, errorMessages})
            setIsLoading(false)
            trackEvent('hubOrderIntakeRMCQuoteRequestFailed', {
              error: error.response?.data
            })
          }
        }
      )
    }
  }

  const fileInputClicked = () => {
    fileInputRef.current?.click()
  }

  const onAttachFile = (event) => {
    processFile(event.target.files)
    event.target.value = null
  }

  const processFile = (files: any) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].name.length > FILE_MAX_NAME_LENGTH) {
        notify(
          'error',
          t('orderIntake.requestForQuote.longFileName', {length: FILE_MAX_NAME_LENGTH})
        )
        return
      }

      if (!isSupportedFileType(files[i].type)) {
        notify('error', t('orderIntake.requestForQuote.noSupportedFileFormat'))
        return
      }

      if (files[i].size > FILE_MAX_SIZE_IN_BYTES) {
        notify('error', t('orderIntake.requestForQuote.tooLargeFile', {fileSize: FILE_MAX_SIZE}))
        return
      }

      readFile(files[i])
    }
  }

  const isSupportedFileType = (fileType: string) => {
    return uploadOnlyPdfFiles
      ? PDF_FILE_TYPE.includes(fileType)
      : SUPPORTED_FILE_TYPES.includes(fileType)
  }

  const readFile = (file: File) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e: any) {
      const fileName = file.name.replace('.pdf', '').replace('.xlsx', '').replace('.xls', '')
      const fileExtension = file.name.split('.').pop()
      const fileDescriptor: RequestForQuoteAttachment = {
        fileName: fileName,
        fileContent: e.target.result,
        fileHash: crypto.createHash('md5').update(e.target.result).digest('hex'),
        fileExtension: fileExtension ?? ''
      }
      setAttachedFile(fileDescriptor)
    }
  }

  const handleStartDateChange = (startDate) => {
    const dateRangeTo = {...availableDateRangeTo}
    dateRangeTo.from = moment(startDate) > moment() ? moment(startDate) : moment().add(1, 'days')
    dateRangeTo.to = moment(startDate).add(100, 'years')
    setAvailableDateRangeTo(dateRangeTo)
  }

  const handleEndDateChange = (endDate) => {
    const dateRangeFrom = {...availableDateRangeFrom}
    dateRangeFrom.to = moment(endDate)
    setAvailableDateRangeFrom(dateRangeFrom)
  }

  const onClosePersistedRequestForQuoteBanner = (action: PersistedRequestForQuoteAction) => {
    switch (action) {
      case PersistedRequestForQuoteAction.CopyPreviousRequest: {
        setIsApplyingPersistedRequestForQuote(true)
        notify('info', t('orderIntake.requestForQuote.persistency.confirmationMessage'), {
          autoHideDuration: 2000
        })
        setShowPersistedRequestForQuoteBanner(false)
        return
      }
      default: {
        setShowPersistedRequestForQuoteBanner(false)
      }
    }
  }

  const applyPersistedRequestForQuote = () => {
    const persistedProjectTypeItem = projectTypes.filter(
      (item) => item.value === persistedRequestForQuote?.projectType
    )

    if (persistedProjectTypeItem && persistedProjectTypeItem.length > 0) {
      setSelectedProjectType(persistedProjectTypeItem[0])
      methods.setValue('projectType', persistedProjectTypeItem[0].value, {
        shouldValidate: true,
        shouldDirty: true
      })
    }

    methods.setValue('phoneNumber', persistedRequestForQuote?.phoneNumber ?? '', {
      shouldValidate: true,
      shouldDirty: true
    })
    methods.setValue('services', persistedRequestForQuote?.services ?? [])

    replaceMaterials(persistedRequestForQuote?.materials ?? [])

    if (persistedRequestForQuote?.selectedSupplier) {
      const persistedSupplierItem = supplierOptions.filter(
        (item) => item === persistedRequestForQuote?.selectedSupplier
      )

      if (persistedSupplierItem && persistedSupplierItem.length > 0) {
        setSelectedSupplier(persistedSupplierItem[0])
        let filteredRegions = filterRegionsBySupplier(
          regionsByBusinessLine ?? [],
          persistedSupplierItem[0]
        )
        setRegionsByBusinessLine(filteredRegions)
        applyPersistedRegion(filteredRegions)
      }
    } else {
      applyPersistedRegion(regionsByBusinessLine)
    }
  }

  const applyPersistedRegion = (regions: SalesOfficeData[]) => {
    const persistedRegionItem = regions.filter(
      (item) => item.region === persistedRequestForQuote?.selectedRegion?.region
    )

    if (regions.length === 1) {
      setIsVisibleRegion(false)
    }

    if (persistedRegionItem && persistedRegionItem.length > 0) {
      setSelectedRegion(persistedRegionItem[0])
      methods.setValue('salesOfficeEmail', persistedRegionItem[0].salesOfficeEmail, {
        shouldValidate: true,
        shouldDirty: true
      })
    }
  }

  const addressAvailabilityValueHandleChange = (
    event: React.SyntheticEvent,
    isAddressAvailable: number
  ) => {
    setAddressAvailabilityValue(isAddressAvailable)
    methods.setValue('projectDetails.projectDestination.street', '')
    methods.setValue('projectDetails.projectDestination.city', '')
    methods.setValue('projectDetails.projectDestination.zip', '')
  }

  const checkMapCoordinates = () => {
    if (!addressAvailabilityValue) return

    const mapClick = methods.getValues('projectDetails.projectDestination.mapCoordinates')
    if (!mapClick) {
      methods.setError('projectDetails.projectDestination.mapCoordinates', {
        type: 'manual',
        message: 'You must click on the map'
      })
    } else {
      methods.clearErrors('projectDetails.projectDestination.mapCoordinates')
    }
  }

  if (isCustomersFetching) {
    return (
      <Box display="flex" justifyContent="center" marginTop={4} marginBottom={4}>
        <CircularProgress size={40} color="inherit" />
      </Box>
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          marginTop={0}
          className={isLoading || isRegionsLoading ? c.isLoading : ''}
        >
          <Grid item xs={12} md={8}>
            <Paper elevation={4}>
              <Grid
                container
                className={c.container}
                data-test-id="request-for-quote-form-container"
              >
                <Box display="flex" flexDirection="column">
                  {isLoading || isRegionsLoading ? (
                    <Box display="flex" justifyContent="center" marginTop={2} marginBottom={4}>
                      <CircularProgress size={40} color="inherit" />
                    </Box>
                  ) : null}
                  <Box>
                    <Typography color="secondary">
                      {t('orderIntake.requestForQuote.customerSelection')}
                    </Typography>
                  </Box>

                  {customers?.length > 1 ? (
                    <CustomersFilter
                      onLight
                      customersOnly
                      data-test-id="request-for-quote-customers"
                    />
                  ) : (
                    <Typography
                      data-test-id="request-for-quote-single-customer"
                      className={c.singleCustomer}
                    >
                      {customers?.length === 1
                        ? customers[0].customerName
                        : t('orderIntake.requestForQuote.noCustomer')}
                    </Typography>
                  )}

                  <Box display="flex" flexDirection="column" marginTop={4}>
                    <Controller
                      control={methods.control}
                      name="selectedCountry"
                      rules={{
                        required: true
                      }}
                      defaultValue={selectedCountry?.value}
                      render={({field}) => (
                        <>
                          <SelectDropdown
                            data-test-id="request-for-quote-country"
                            label={t('orderIntake.requestForQuote.country')}
                            options={countries}
                            selectedItem={selectedCountry}
                            renderItem={(item: LabelValueType) => (
                              <Typography>{item.label}</Typography>
                            )}
                            showSearch={false}
                            stringifyItem={(item: LabelValueType) => `${item.label}`}
                            onChange={(value: LabelValueType) => {
                              field.onChange(value.value)
                              setSelectedCountry(value)
                              setSelectedBusinessLine(undefined)
                            }}
                            className={c.projectType}
                            onLight
                          />

                          <Typography
                            variant="caption"
                            component="span"
                            color="secondary"
                            style={{marginTop: '6px'}}
                          >
                            {t('required')}
                          </Typography>
                        </>
                      )}
                    />
                  </Box>

                  <Box display="flex" flexDirection="column" marginTop={4}>
                    <Controller
                      control={methods.control}
                      name="businessLine"
                      rules={{
                        required: true
                      }}
                      render={({field}) => (
                        <>
                          <SelectDropdown
                            data-test-id="request-for-quote-business-line"
                            label={t('orderIntake.requestForQuote.businessLine')}
                            options={getBusinessLinesForSelectedCountry().sort((a, b) =>
                              a.value.localeCompare(b.value)
                            )}
                            selectedItem={selectedBusinessLine}
                            renderItem={(item: LabelValueCodeType) => (
                              <Typography>{item.label}</Typography>
                            )}
                            showSearch={false}
                            stringifyItem={(item: LabelValueCodeType) => `${item.label}`}
                            onChange={(value: LabelValueCodeType) => {
                              field.onChange(value.value)
                              setSelectedBusinessLine(value)
                              setIsApplyingPersistedRequestForQuote(false)
                              setShowPersistedRequestForQuoteBanner(true)
                              setShowSupplier(false)
                              setSelectedSupplier(undefined)
                              setIsVisibleRegion(true)
                            }}
                            className={c.projectType}
                            onLight
                          />

                          <Typography
                            variant="caption"
                            component="span"
                            color="secondary"
                            style={{marginTop: '6px'}}
                          >
                            {t('required')}
                          </Typography>
                        </>
                      )}
                    />
                  </Box>

                  {showPersistedRequestForQuoteBanner && persistedRequestForQuote && (
                    <PersistedRequestForQuoteBanner
                      onClose={onClosePersistedRequestForQuoteBanner}
                    />
                  )}

                  <SectionHeader
                    data-test-id="request-for-quote-section-project-details"
                    title={t('orderIntake.requestForQuote.projectDetails')}
                  />

                  <Box mb={2}>
                    <Typography color="secondary">
                      {t('orderIntake.requestForQuote.projectDetailsSubheader')}
                    </Typography>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Controller
                          control={methods.control}
                          name="projectDetails.projectStartDate"
                          rules={{
                            required: true
                          }}
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          render={({field, fieldState}) => {
                            return (
                              <>
                                <SingleDatePicker
                                  onLight
                                  noQuickSelection
                                  availableDateRange={availableDateRangeFrom}
                                  label={t('orderIntake.requestForQuote.startDate')}
                                  data-test-id={'request-for-quote-start-date-datepicker'}
                                  fullWidth
                                  showTooltip={true}
                                  tooltipLabel={t('orderIntake.dateChanged')}
                                  date={field.value ? moment(field.value) : null}
                                  handleDateChange={({startDate}) => {
                                    field.onChange(moment(startDate).format(MOMENTJS_DATE_FORMAT))
                                    handleStartDateChange(startDate)
                                  }}
                                />
                                <Typography
                                  variant="caption"
                                  component="span"
                                  color="secondary"
                                  style={{marginTop: '6px'}}
                                >
                                  {t('required')}
                                </Typography>
                              </>
                            )
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Controller
                          control={methods.control}
                          rules={{
                            required: true
                          }}
                          name="projectDetails.projectEndDate"
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          render={({field, fieldState}) => {
                            return (
                              <>
                                <SingleDatePicker
                                  onLight
                                  noQuickSelection
                                  availableDateRange={availableDateRangeTo}
                                  label={t('orderIntake.requestForQuote.endDate')}
                                  data-test-id={'request-for-quote-end-date-datepicker'}
                                  fullWidth
                                  showTooltip={true}
                                  tooltipLabel={t('orderIntake.dateChanged')}
                                  date={field.value ? moment(field.value) : null}
                                  handleDateChange={({endDate}) => {
                                    field.onChange(moment(endDate).format(MOMENTJS_DATE_FORMAT))
                                    handleEndDateChange(endDate)
                                  }}
                                />
                                <Typography
                                  variant="caption"
                                  component="span"
                                  color="secondary"
                                  style={{marginTop: '6px'}}
                                >
                                  {t('required')}
                                </Typography>
                              </>
                            )
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Box display="flex" flexDirection="column" marginTop={2}>
                    <Controller
                      control={methods.control}
                      name="projectType"
                      rules={{
                        required: true
                      }}
                      render={({field}) => (
                        <>
                          <SelectDropdown
                            data-test-id="request-for-quote-project-type"
                            label={t('orderIntake.requestForQuote.projectType')}
                            options={projectTypes}
                            selectedItem={selectedProjectType}
                            renderItem={(item: LabelValueType) => (
                              <Typography>{item.label}</Typography>
                            )}
                            showSearch={false}
                            stringifyItem={(item: LabelValueType) => `${item.label}`}
                            onChange={(value: LabelValueType) => {
                              field.onChange(value.value)
                              setSelectedProjectType(value)
                            }}
                            className={c.projectType}
                            onLight
                          />

                          <Typography
                            variant="caption"
                            component="span"
                            color="secondary"
                            style={{marginTop: '6px'}}
                          >
                            {t('required')}
                          </Typography>
                        </>
                      )}
                    />
                  </Box>

                  {showSupplier && (
                    <Box display="flex" flexDirection="column" marginTop={2}>
                      <Controller
                        control={methods.control}
                        name="supplier"
                        rules={{
                          required: true
                        }}
                        render={({field}) => (
                          <>
                            <SelectDropdown
                              data-test-id="request-for-quote-supplier"
                              label={t('orderIntake.requestForQuote.supplier')}
                              options={supplierOptions}
                              selectedItem={selectedSupplier}
                              renderItem={(item: string) => <Typography>{item}</Typography>}
                              showSearch={false}
                              stringifyItem={(item: string) => `${item}`}
                              onChange={(value: string) => {
                                field.onChange(value)
                                setIsApplyingPersistedRequestForQuote(false)
                                setSelectedSupplier(value)
                              }}
                              className={c.projectType}
                              onLight
                            />

                            <Typography
                              variant="caption"
                              component="span"
                              color="secondary"
                              style={{marginTop: '6px'}}
                            >
                              {t('required')}
                            </Typography>
                          </>
                        )}
                      />
                    </Box>
                  )}

                  {isVisibleRegion && (
                    <Box display="flex" flexDirection="column" marginTop={2}>
                      <Controller
                        control={methods.control}
                        name="salesOfficeEmail"
                        rules={{
                          required: true
                        }}
                        render={({field}) => (
                          <>
                            <SelectDropdown
                              disabled={regionsByBusinessLine.length === 0}
                              data-test-id="request-for-quote-region"
                              label={t('orderIntake.requestForQuote.region')}
                              options={regionsByBusinessLine.sort((a, b) =>
                                a.region.localeCompare(b.region)
                              )}
                              selectedItem={selectedRegion}
                              renderItem={(item: SalesOfficeData) => (
                                <Typography>{`${item.region}`}</Typography>
                              )}
                              showSearch={false}
                              stringifyItem={(item: SalesOfficeData) => `${item.region}`}
                              onChange={(value: SalesOfficeData) => {
                                field.onChange(value.salesOfficeEmail)
                                setIsApplyingPersistedRequestForQuote(false)
                                setSelectedRegion(value)
                              }}
                              className={c.projectType}
                              onLight
                            />

                            <Typography
                              variant="caption"
                              component="span"
                              color="secondary"
                              style={{marginTop: '6px'}}
                            >
                              {t('required')}
                            </Typography>
                          </>
                        )}
                      />
                    </Box>
                  )}

                  {isVisiblePlant && (
                    <Box display="flex" flexDirection="column" marginTop={2}>
                      <Controller
                        control={methods.control}
                        name="plant"
                        rules={{
                          required: true
                        }}
                        render={({field}) => (
                          <>
                            <SelectDropdown
                              data-test-id="request-for-quote-plant"
                              label={t('orderIntake.requestForQuote.plant')}
                              options={plantsByRegions.sort((a, b) =>
                                a.plant.localeCompare(b.plant)
                              )}
                              selectedItem={selectedPlant}
                              renderItem={(item: SalesOfficeData) => (
                                <Typography>{`${item.plant}`}</Typography>
                              )}
                              showSearch={false}
                              stringifyItem={(item: SalesOfficeData) => `${item.plant}`}
                              onChange={(value: SalesOfficeData) => {
                                field.onChange(value.plant)
                                setSelectedPlant(value)
                                methods.setValue('salesOfficeEmail', value.salesOfficeEmail)
                              }}
                              className={c.projectType}
                              onLight
                            />

                            <Typography
                              variant="caption"
                              component="span"
                              color="secondary"
                              style={{marginTop: '6px'}}
                            >
                              {t('required')}
                            </Typography>
                          </>
                        )}
                      />
                    </Box>
                  )}

                  <Box display="flex" flexDirection="column" marginTop={2}>
                    <Controller
                      control={methods.control}
                      name="projectDetails.projectDestination.name"
                      rules={{
                        maxLength: MAX_CHARS,
                        required: false,
                        validate: (value) => {
                          return validateInput(value ?? '')
                        }
                      }}
                      render={({field, fieldState}) => {
                        return (
                          <>
                            <BorderedTextField
                              data-test-id="request-for-quote-destination"
                              label={t('orderIntake.requestForQuote.destinationName')}
                              placeholder={t('orderIntake.requestForQuote.destinationName')}
                              value={field.value ?? ''}
                              onChange={(e) => {
                                field.onChange(removeInvalidCharacters(e.target.value))
                              }}
                              onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                              inputProps={{maxLength: MAX_CHARS}}
                              error={!!fieldState.error}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip
                                      data-test-id="request-for-quote-destination-tooltip"
                                      title={t('orderIntake.requestForQuote.destinationTooltip')}
                                    >
                                      <Box className={c.tooltip}>
                                        <InfoIcon color="secondary" style={{cursor: 'pointer'}} />
                                      </Box>
                                    </Tooltip>
                                  </InputAdornment>
                                )
                              }}
                            />
                            {!!fieldState.error && (
                              <Typography variant="caption" component="span" color="error">
                                {t('orderIntake.requestForQuote.emptyTextOrInvalidCharacters')}
                              </Typography>
                            )}
                          </>
                        )
                      }}
                    />
                  </Box>

                  <Box display="flex" flexDirection="column" mb={2} marginTop={2}>
                    <Tabs
                      value={addressAvailabilityValue}
                      onChange={addressAvailabilityValueHandleChange}
                      indicatorColor="primary"
                      textColor="inherit"
                      variant="fullWidth"
                      centered={false}
                      color="primary"
                      className={c.tabs}
                      TabIndicatorProps={{
                        style: {
                          display: 'none'
                        }
                      }}
                    >
                      <Tab
                        value={RequestForQuoteFormAddressAvailabilityType.AddressAvailable}
                        className={c.buttonTab}
                        label={t('orderIntake.requestForQuote.addressAvailable')}
                        data-test-id="request-for-quote-address-available-tab"
                      />
                      <Tab
                        value={RequestForQuoteFormAddressAvailabilityType.AddressNotAvailable}
                        className={c.buttonTab}
                        label={t('orderIntake.requestForQuote.addressNotAvailable')}
                        data-test-id="request-for-quote-address-not-available-tab"
                      />
                    </Tabs>
                  </Box>

                  {addressAvailabilityValue ? (
                    <>
                      <Box mb={2} marginTop={2}>
                        <Typography color="textPrimary">
                          {t('orderIntake.requestForQuote.projectAddressMapInfo')}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" marginTop={2} marginBottom={2}>
                        <Controller
                          control={methods.control}
                          name="projectDetails.projectDestination.mapCoordinates"
                          rules={{required: addressAvailabilityValue ? true : false}}
                          render={({field, formState}) => (
                            <>
                              <GoogleMapWithSearchbox
                                mapCoordinatesField={field}
                                mapCoordinatesFormState={formState}
                              />
                            </>
                          )}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box display="flex" flexDirection="column" marginTop={2}>
                        <Controller
                          control={methods.control}
                          name="projectDetails.projectDestination.street"
                          rules={{
                            maxLength: MAX_CHARS,
                            required: false,
                            validate: (value) => {
                              return validateInput(value ?? '')
                            }
                          }}
                          render={({field, fieldState}) => {
                            return (
                              <>
                                <BorderedTextField
                                  data-test-id="request-for-quote-street"
                                  label={t('orderIntake.requestForQuote.street')}
                                  placeholder={t('orderIntake.requestForQuote.street')}
                                  value={field.value ?? ''}
                                  onChange={(e) => {
                                    field.onChange(removeInvalidCharacters(e.target.value))
                                  }}
                                  onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                                  inputProps={{maxLength: MAX_CHARS}}
                                  error={!!fieldState.error}
                                />
                                {!!fieldState.error && (
                                  <Typography variant="caption" component="span" color="error">
                                    {t('orderIntake.requestForQuote.emptyTextOrInvalidCharacters')}
                                  </Typography>
                                )}
                              </>
                            )
                          }}
                        />
                      </Box>
                      <Grid container spacing={2} marginTop={2} marginBottom={2}>
                        <Grid item xs={12} md={6}>
                          <Box display="flex" flexDirection="column">
                            <Controller
                              control={methods.control}
                              name="projectDetails.projectDestination.zip"
                              rules={{
                                maxLength: MAX_CHARS,
                                required: addressAvailabilityValue ? false : true,
                                validate: (value) => {
                                  return validateInput(value ?? '')
                                }
                              }}
                              render={({field, fieldState}) => {
                                return (
                                  <>
                                    <BorderedTextField
                                      data-test-id="request-for-quote-zip"
                                      label={t('orderIntake.requestForQuote.zip')}
                                      placeholder={t('orderIntake.requestForQuote.zip')}
                                      value={field.value ?? ''}
                                      onChange={(e) => {
                                        field.onChange(removeInvalidCharacters(e.target.value))
                                      }}
                                      onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                                      inputProps={{maxLength: MAX_CHARS}}
                                      error={!!fieldState.error}
                                    />
                                    {!!fieldState.error && (
                                      <Typography variant="caption" component="span" color="error">
                                        {t(
                                          'orderIntake.requestForQuote.emptyTextOrInvalidCharacters'
                                        )}
                                      </Typography>
                                    )}
                                    <Typography
                                      variant="caption"
                                      component="span"
                                      color="secondary"
                                      style={{marginTop: '6px'}}
                                    >
                                      {t('required')}
                                    </Typography>
                                  </>
                                )
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box display="flex" flexDirection="column">
                            <Controller
                              control={methods.control}
                              name="projectDetails.projectDestination.city"
                              rules={{
                                maxLength: MAX_CHARS,
                                required: addressAvailabilityValue ? false : true,
                                validate: (value) => {
                                  return validateInput(value ?? '')
                                }
                              }}
                              render={({field, fieldState}) => {
                                return (
                                  <>
                                    <BorderedTextField
                                      data-test-id="request-for-quote-city"
                                      label={t('orderIntake.requestForQuote.city')}
                                      placeholder={t('orderIntake.requestForQuote.city')}
                                      value={field.value ?? ''}
                                      onChange={(e) => {
                                        field.onChange(removeInvalidCharacters(e.target.value))
                                      }}
                                      onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                                      inputProps={{maxLength: MAX_CHARS}}
                                      error={!!fieldState.error}
                                    />
                                    {!!fieldState.error && (
                                      <Typography variant="caption" component="span" color="error">
                                        {t(
                                          'orderIntake.requestForQuote.emptyTextOrInvalidCharacters'
                                        )}
                                      </Typography>
                                    )}
                                    <Typography
                                      variant="caption"
                                      component="span"
                                      color="secondary"
                                      style={{marginTop: '6px'}}
                                    >
                                      {t('required')}
                                    </Typography>
                                  </>
                                )
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Box display="flex" flexDirection="column" marginTop={2}>
                    <Controller
                      control={methods.control}
                      name={'phoneNumber'}
                      rules={{
                        required: true
                      }}
                      render={({field, fieldState}) => (
                        <>
                          <PhoneNumberTextField
                            bordered
                            id={field.name}
                            ref={field.ref}
                            name={field.name}
                            data-test-id="request-for-quote-phone-number"
                            value={field.value ?? ''}
                            onPhoneChange={field.onChange}
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                            onFocus={() => checkMapCoordinates()}
                            InputLabelProps={{shrink: !isEmpty(field.value)}}
                            options={{
                              initialCountry: field.value ? '' : (countryCode as CountryCode) ?? '',
                              nationalMode: false,
                              formatOnDisplay: false
                            }}
                            label={t('orderIntake.requestForQuote.phoneNumber')}
                            error={
                              !!fieldState.error ||
                              (!!field.value && !isValidPhoneNumber(field.value))
                            }
                          />
                          <Typography
                            variant="caption"
                            component="span"
                            color="secondary"
                            style={{marginTop: '6px'}}
                          >
                            {t('required')}
                          </Typography>
                        </>
                      )}
                    />
                  </Box>

                  {!isNamRegion && (
                    <>
                      <Box mt={4} mb={2} display="flex">
                        <Typography color="textPrimary">
                          {t('orderIntake.requestForQuote.projectStatus')}
                        </Typography>
                        <Box ml={1}>
                          <Tooltip
                            data-test-id="request-for-quote-project-status-tooltip"
                            title={
                              methods.getValues().projectDetails.projectStatus ===
                              RequestForQuoteFormStatusType.Tender
                                ? t('orderIntake.requestForQuote.projectStatusTenderTooltip')
                                : t('orderIntake.requestForQuote.projectStatusLetTooltip')
                            }
                          >
                            <InfoIcon color="secondary" style={{cursor: 'pointer'}} />
                          </Tooltip>
                        </Box>
                      </Box>

                      <Controller
                        control={methods.control}
                        name="projectDetails.projectStatus"
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        render={({field, fieldState}) => {
                          return (
                            <Tabs
                              value={field.value}
                              onChange={(_, value: RequestForQuoteFormStatusType) => {
                                field.onChange(value)
                              }}
                              indicatorColor="primary"
                              textColor="inherit"
                              variant="fullWidth"
                              centered={false}
                              color="primary"
                              className={c.tabs}
                              TabIndicatorProps={{
                                style: {
                                  display: 'none'
                                }
                              }}
                            >
                              <Tab
                                data-test-id="request-for-quote-project-status-tender"
                                value={RequestForQuoteFormStatusType.Tender}
                                className={c.buttonTab}
                                label={t('orderIntake.requestForQuote.projectStatusTender')}
                              />
                              <Tab
                                data-test-id="request-for-quote-project-status-let"
                                value={RequestForQuoteFormStatusType.Let}
                                className={c.buttonTab}
                                label={t('orderIntake.requestForQuote.projectStatusLet')}
                              />
                            </Tabs>
                          )
                        }}
                      />
                    </>
                  )}
                  <SectionHeader
                    data-test-id="request-for-quote-section-project-materials"
                    title={t('orderIntake.requestForQuote.materialHeader')}
                  />

                  <Typography color="secondary">
                    {t('orderIntake.requestForQuote.materialSubHeader')}
                  </Typography>

                  {materials && (
                    <Box mt={2}>
                      {materials.map((material, index) => {
                        return (
                          <RequestForQuoteMaterialForm
                            key={material.id}
                            isRMC={isRMCBusinessLine}
                            isBaggedMaterial={isBaggedMaterialsBusinessLine}
                            isUSUser={(countryCode as CountryCode) === 'US'}
                            canRemove={materials.length > 1}
                            materialIndex={index}
                            removeMaterial={() => removeMaterial(index)}
                            onChange={() => checkBussinessLine()}
                          />
                        )
                      })}
                    </Box>
                  )}

                  <Button
                    className={c.addAnotherMaterial}
                    startIcon={<Add />}
                    data-test-id="request-for-quote-add-material"
                    onClick={addMaterial}
                  >
                    {t('orderIntake.requestForQuote.addAnotherMaterial')}
                  </Button>

                  <Box
                    display="flex"
                    flexDirection="column"
                    marginTop={2}
                    data-test-id="request-for-quote-services"
                  >
                    <CheckboxGroup
                      control={methods.control}
                      data-test-id="request-for-quote-services"
                      label={t('orderIntake.requestForQuote.services')}
                      name="services"
                      options={services}
                      config={servicesConfig}
                      row={true}
                    />
                  </Box>

                  <SectionHeader
                    data-test-id="request-for-quote-section-project-addition-info"
                    title={t('orderIntake.requestForQuote.additionalInfo')}
                  />
                  <Box mb={2}>
                    <Typography color="textPrimary">
                      {t('orderIntake.requestForQuote.additionalInfoSubheader')}
                    </Typography>
                  </Box>

                  <Features name="OrderIntakeRequestForQuoteUploadFile">
                    <Box marginBottom={4}>
                      <Box
                        className={c.attachmentContainer}
                        data-test-id="request-for-add-attachment"
                        onClick={fileInputClicked}
                      >
                        <Box display="flex">
                          <AttachFileOutlined />
                          <Typography>
                            {uploadOnlyPdfFiles
                              ? t('orderIntake.requestForQuote.attachOnlyPdfFile')
                              : t('orderIntake.requestForQuote.attachFile')}
                          </Typography>
                        </Box>

                        <Typography color="secondary" variant="caption" style={{marginLeft: '6px'}}>
                          {t('orderIntake.requestForQuote.fileNameValidation')}
                        </Typography>

                        <input
                          style={{display: 'none'}}
                          ref={fileInputRef}
                          type="file"
                          accept={uploadOnlyPdfFiles ? '.pdf' : '.pdf, .xls, .xlsx'}
                          multiple={false}
                          onChange={onAttachFile}
                        />
                      </Box>

                      {attachedFile && (
                        <Box display="flex" alignItems="center" marginTop={2}>
                          <img src={DownloadPDFPNG} alt="" height="30px" width="30px" />
                          <Tooltip title={attachedFile.fileName}>
                            <Box
                              data-test-id="request-for-quote-attachment-file-name"
                              className={c.fileName}
                            >
                              {attachedFile.fileName}
                            </Box>
                          </Tooltip>
                          <Box>
                            <IconButton
                              data-test-id="request-for-quote-remove-attachment"
                              onClick={() => setAttachedFile(undefined)}
                              style={{border: 'none', boxShadow: 'none'}}
                            >
                              <Close />
                            </IconButton>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Features>

                  <Box display="flex" flexDirection="column" marginTop={2}>
                    <Controller
                      control={methods.control}
                      name="poNumber"
                      rules={{
                        maxLength: MAX_CHARS,
                        validate: (value) => {
                          return validateInput(value ?? '')
                        }
                      }}
                      render={({field, fieldState}) => {
                        return (
                          <>
                            <BorderedTextField
                              data-test-id="request-for-quote-purchaseOrderNumber"
                              label={t('orderIntake.requestForQuote.purchaseOrderNumber')}
                              placeholder={t('orderIntake.requestForQuote.purchaseOrderNumber')}
                              value={field.value ?? ''}
                              onChange={(e) => {
                                field.onChange(removeInvalidCharacters(e.target.value))
                              }}
                              onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                              inputProps={{maxLength: MAX_CHARS}}
                              error={!!fieldState.error}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip
                                      title={t(
                                        'orderIntake.requestForQuote.purchaseOrderNumberTooltip'
                                      )}
                                    >
                                      <Box className={c.tooltip}>
                                        <InfoIcon color="secondary" style={{cursor: 'pointer'}} />
                                      </Box>
                                    </Tooltip>
                                  </InputAdornment>
                                )
                              }}
                            />
                            {!!fieldState.error && (
                              <Typography variant="caption" component="span" color="error">
                                {t('orderIntake.requestForQuote.emptyTextOrInvalidCharacters')}
                              </Typography>
                            )}
                          </>
                        )
                      }}
                    />
                  </Box>

                  <Box display="flex" flexDirection="column" marginTop={2}>
                    <Controller
                      control={methods.control}
                      name="comment"
                      rules={{
                        maxLength: MAX_CHARS,
                        validate: (value) => {
                          return validateMultilineInput(value ?? '')
                        }
                      }}
                      render={({field, fieldState}) => {
                        return (
                          <>
                            <BorderedTextField
                              data-test-id="request-for-quote-comment"
                              label={t('orderIntake.requestForQuote.comment')}
                              placeholder={t('orderIntake.requestForQuote.comment')}
                              value={field.value ?? ''}
                              multiline={true}
                              minRows={4}
                              onChange={(e) => {
                                field.onChange(removeInvalidCharacters(e.target.value))
                              }}
                              onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                              inputProps={{maxLength: MAX_CHARS}}
                              error={!!fieldState.error}
                            />
                            {!!fieldState.error && (
                              <Typography variant="caption" component="span" color="error">
                                {t('orderIntake.requestForQuote.invalidCharacters')}
                              </Typography>
                            )}
                          </>
                        )
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper elevation={4} style={{height: '100%'}}>
              <Grid
                container
                className={classNames(c.container, c.stickyContainer)}
                data-test-id="request-for-quote-submit-container"
              >
                {isSmartRockEnabled && enabledSmartRock && (
                  <Box className={c.smartRockSection}>
                    <Box className={c.smartRockTag}>
                      {t('orderIntake.requestForQuote.smartRock.saveMore')}
                    </Box>

                    <Box className={c.smartRockHeadlinePart1}>
                      {t('orderIntake.requestForQuote.smartRock.headlinePart1')}
                    </Box>
                    <Box className={c.smartRockHeadlinePart2}>
                      {t('orderIntake.requestForQuote.smartRock.headlinePart2')}
                    </Box>
                    <Box className={c.smartRockTextPart}>
                      <Switch
                        classes={{
                          colorSecondary: c.switchStyle
                        }}
                        aria-label="Multiple download switch"
                        size="medium"
                        data-test-id="request-for-quote-smart-rock-requested"
                        checked={requestSmartRock}
                        onChange={(_, checked) => setRequestSmartRock(checked)}
                      />

                      <Box className={c.smartRockText}>
                        <Typography variant="body2">
                          {t('orderIntake.requestForQuote.smartRock.bodyPart1')}{' '}
                          <Link
                            href={smartRockExternalLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                              trackEvent('hubOrderIntakeRequestForQuotesSmartRockLinkClicked', {
                                timeStamp: new Date().toLocaleString(),
                                email: userEmail,
                                company: userCompany,
                                country: countryCode,
                                market: userMarketId
                              })
                            }}
                          >
                            {t('orderIntake.requestForQuote.smartRock.linkText')}{' '}
                          </Link>
                          {t('orderIntake.requestForQuote.smartRock.bodyPart2')}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}

                <Box mb={1}>
                  <Typography variant="body2" color="textPrimary">
                    {t('orderIntake.requestForQuote.submitButtonCaption')}
                  </Typography>
                </Box>

                <Box className={c.submitButtonContainer} marginTop={2}>
                  <Button
                    type="submit"
                    data-test-id="request-for-quote-submit-button"
                    color="primary"
                    fullWidth
                    disabled={!methods.formState.isValid}
                  >
                    {!isLoading ? (
                      <ArrowRightAlt className={c.submitIcon} />
                    ) : (
                      <CircularProgress size={20} color="inherit" className={c.submitIcon} />
                    )}
                    {t('orderIntake.requestForQuote.requestQuote')}
                  </Button>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {confirmationStatusScreen.show && (
          <Dialog open={true} onClose={handleClose}>
            <DialogContent>
              <RequestForQuoteFormConfirmation
                showError={confirmationStatusScreen.isError}
                errorMessages={confirmationStatusScreen.errorMessages}
                onClose={handleClose}
              />
            </DialogContent>
          </Dialog>
        )}
      </form>
    </FormProvider>
  )
}
