import {Box} from '@material-ui/core'
import React from 'react'

import {useBranding} from '../../../../Organisms/Branding'
import CustomersFilter from '../../../../Organisms/Customers/CustomersFilter'
import {CustomerSimpleLookup} from '../../../../Organisms/Customers/CustomerSimpleLookup'
import {useFeaturesState} from '../../../../Organisms/Features'
import {GenericFilterBar} from '../../../../Organisms/Filters/GenericFilterBar'
import ProjectsSitesFilter from '../../../../Organisms/Projects/ProjectsSitesFilter'
import {usePermissions} from '../../../../Permissions'

import DateFilter from './DateFilter'
import LookupFilter from './LookupFilter'
import SitesFilter from './SitesFilter'

export interface FilterBarErrorMaterialTest {
  key: string
  label: string
}

interface Props {
  errors?: FilterBarErrorMaterialTest[]
  lookupAnalyticsId?: string
  userId?: string
}

const Filters: React.FC<Props> = ({lookupAnalyticsId, userId, ...props}) => {
  const branding = useBranding()
  const country = branding.country ?? ''
  const {displayCustomerSimpleLookup} = usePermissions()
  const {errors} = props
  const isLookupError = errors?.some((err) => err.key === 'noLookup')
  const {getFeature} = useFeaturesState()
  const customerSearchByNameEnabled = getFeature('CustomersByName')
  return (
    <GenericFilterBar
      data-test-id="materialTest-filters"
      toolbarProps={{notEndLast: true}}
      {...props}
    >
      <Box width="100%" mt={-1}>
        <Box display="flex" height={57} mt={1} style={{float: 'left'}}>
          {displayCustomerSimpleLookup ? (
            <CustomerSimpleLookup
              data-test-id="orders-filters-lookup"
              customerSearchByName={customerSearchByNameEnabled}
            />
          ) : (
            <CustomersFilter onLight customersOnly />
          )}
        </Box>
        <Box height={57} width={400} mt={1} ml={2} style={{float: 'right'}}>
          <LookupFilter hasError={isLookupError} analyticsId={lookupAnalyticsId} userId={userId} />
        </Box>
        <Box display="inline-flex" height={57} mt={1}>
          <DateFilter />
          <Box mx={1} />
          {['HK', 'AU'].includes(country) ? (
            <ProjectsSitesFilter onLight />
          ) : (
            <SitesFilter onLight />
          )}
        </Box>
      </Box>
    </GenericFilterBar>
  )
}

export default Filters
