import {Box, Paper, Typography} from '@material-ui/core'
import React from 'react'
import {Document, Page, pdfjs} from 'react-pdf'

// Textlayer out of alignment Isse: https://github.com/wojtekmaj/react-pdf/issues/332
import './PdfViewer.css'
import {useTranslation} from 'react-i18next'

import {isIE11} from './PdfUtils'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface PDFViewerProps {
  file?: string
  showPdf: boolean
  page?: number
}

const PreviewUnavailable = () => {
  const {t} = useTranslation()
  return (
    <Paper variant="outlined" square style={{height: '700px'}}>
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant="h1" gutterBottom color="secondary">
          {t('certificate.generateCertificate.previewUnavailable.title')}
        </Typography>
        <Typography variant="h4" gutterBottom color="secondary">
          {t('certificate.generateCertificate.previewUnavailable.subtitle')}
        </Typography>
      </Box>
    </Paper>
  )
}

export const PdfViewer = ({file, page, showPdf}: PDFViewerProps): JSX.Element =>
  showPdf && !isIE11 ? (
    <Paper
      elevation={2}
      style={{height: '842px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
    >
      <Document
        file={file}
        onLoadError={(error: Error) =>
          console.error(`PdfViewer: Error while loading document! ${error.message}`)
        }
        onSourceError={(error: Error) =>
          console.error(`PdfViewer: Error while retrieving document source! ${error.message}`)
        }
        className="PdfView"
        renderMode="svg"
      >
        <Page pageIndex={page || 0} />
      </Document>
    </Paper>
  ) : (
    <PreviewUnavailable />
  )

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NativePDFViewer = ({file, page}: PDFViewerProps) => {
  return (
    <object data={file} type="application/pdf" height="500px" width="500px">
      <p>
        It appears you don't have Adobe Reader or PDF support in this web browser.{' '}
        <a href="lorem.pdf">Click here to download the PDF</a>. Or{' '}
        <a href="http://get.adobe.com/reader/" target="_blank" rel="noopener noreferrer">
          click here to install Adobe Reader
        </a>
        .
      </p>
      <embed src={file} type="application/pdf" />
    </object>
  )
}
