import {FilterBar} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import {Moment} from 'moment'
import React from 'react'

import {Plant} from '../../../Organisms/Plants/Plants.types'
import {PlantsDropdown} from '../../../Organisms/Plants/PlantsDropdown'
import {ProductsDropdown} from '../../../Organisms/Products/ProductsDropdown'
import {Product} from '../Cement/types'

import {DateFilter} from './DateFilter'

export interface FilterBarErrorCertificate {
  key: string
  label: string
}

export interface MaterialCertificatesFilter {
  materialName?: string
  startDate: string
  endDate: string
  skip: number
  limit: number
  plantId?: string
  sortedByKey: string
  sortedByDirection: 'asc' | 'desc'
  customerId?: string
  open: boolean
}

interface Props {
  errors?: FilterBarErrorCertificate[]
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  plantsError?: Error | unknown
  plants?: Plant[]
  handlePlants: (plant: Plant) => void
  selectedPlantId?: string
  selectedProductId?: string
  handleDates: (filter: {startDate: Moment | null; endDate: Moment | null}) => void
  startDate: Moment | null
  endDate: Moment | null
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  productsError?: Error | unknown
  products?: Product[]
  handleProducts: (product: Product) => void
}

export const Filters: React.FC<Props> = ({
  plantsError,
  plants = [],
  handlePlants,
  selectedPlantId,
  selectedProductId,
  handleDates,
  startDate,
  endDate,
  productsError,
  products,
  handleProducts,
  ...props
}) => (
  <FilterBar data-test-id="certificates-filters" toolbarProps={{notEndLast: true}} {...props}>
    <DateFilter filter={{startDate, endDate}} onDateChange={handleDates} />
    <Box mx={1} />
    <PlantsDropdown
      onLight
      error={plantsError}
      plants={plants}
      onChange={handlePlants}
      selectedPlantId={selectedPlantId}
    />
    <Box mx={1} />
    <ProductsDropdown
      onLight
      error={productsError}
      products={products}
      onChange={handleProducts}
      selectedProductId={selectedProductId}
    />
  </FilterBar>
)
