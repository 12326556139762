import {BusinessLineType, Dictionary} from '../../common/types'

import {OrderRequestStatus, ShippingType} from './OrderIntake.enums'
import {AxiosError} from 'axios'

export enum QuantityType {
  TruckCapacity = 'truckCapacity',
  Load = 'load'
}

export enum TimeFormatValues {
  AM = 'AM',
  PM = 'PM'
}

export enum TimePickerType {
  Earliest,
  Latest
}

// GET Optimized Options
export type PlantDto = {
  street?: string
  postalCode?: string
  city?: string
  state?: string
  country?: string
  plantName?: string
  plantNumber?: string
  configurableSlots: ConfigurableTimeSlot[]
}

export type OptimizedMaterialDto = {
  materialNumber?: string
  materialEnteredNumber?: string
  materialDescription?: string
  materialEnteredDescription?: string
  contractItemDescription?: string
  contractNumber?: string
  contractItemPositionNumber?: string
  businessLine?: BusinessLineType
  quantityType?: QuantityType
  poNumber?: string
  // optimized
  invalid?: boolean
  deactivationReason: string
  canDisableEmailSending?: boolean
  shippingType: ShippingType
  timeZone?: string
}
export type ContractValidityPeriodDto = {
  contractValidityStartDate: string
  contractValidityEndDate: string
}
export type ContractUnitDto = {
  siteNumber: string
  siteName?: string
  street?: string
  postalCode?: string
  city?: string
  state?: string
  country?: string
  customerNumber?: string
  //customerReference?: string
  driverInstructions?: string
  contact: Contact
  timeZone?: string
  materials: OptimizedMaterialDto[]
  contractValidityPeriods: Record<ShippingType, ContractValidityPeriodDto>
  truckCapacity: Record<ShippingType, TruckCapacity>
}
export type OptimizedOption = {
  plant: PlantDto
  dateRange: Record<ShippingType, DateRange>
  businessHours: Record<ShippingType, BusinessHours>
  defaultDeliveryWindow: Record<ShippingType, DefaultDeliveryWindow>
  dispatchPhoneNumbers: string[]
  contractUnit: ContractUnitDto[]
}

// GET Request Payload
export interface OrderIntakeMaterialOptionPayload {
  shippingAddress: ShippingAddress
  material: Material
  dateRange: DateRange
  deactivationReason: string
  dispatchPhoneNumbers: string[]
  driverInstructions?: string
  invalid: boolean
  customerNumber: string
  customerReference: string
  contact: Contact
  truckCapacity: TruckCapacity
  plant: Plant
  shippingType: ShippingType
  businessHours: BusinessHours
  defaultDeliveryWindow: DefaultDeliveryWindow
  canDisableEmailSending: boolean
}

// POST Request Payload

export type OrderIntakePayload = {
  siteNumber: string
  materialNumber: string
  materialDescription?: string
  materialEnteredNumber: string
  customerReference: string
  contractItemPositionNumber: string
  contractNumber: string
  deliveryDate: string
  deliveryTime: DeliveryTime
  shippingType: ShippingType
  capacity: CapacityRequest
  additionalDriverInfo?: AdditionalDriverInfo
  isSendingConfirmationEmailUnChecked: boolean
  contact: Contact
  haulerInfo?: HaulerInfo
  customerId: string
  businessLineCode: string
  plantName: string
  plantNumber: string
}

export type PersistedOrderRequest = Pick<
  OrderIntakePayload,
  | 'additionalDriverInfo'
  | 'capacity'
  | 'customerReference'
  | 'deliveryTime'
  | 'materialNumber'
  | 'materialDescription'
  | 'materialEnteredNumber'
  | 'contractItemPositionNumber'
  | 'isSendingConfirmationEmailUnChecked'
  | 'plantName'
  | 'plantNumber'
>

export interface OrderRequest {
  isNoDatesAvailable: boolean
  isDateChange: boolean
  isMaterialHighlighted: boolean
  initialDeliveryDate: string
  initialDeliveryTime: DeliveryTime
  initialTruckQuantity: number
  initialFullName: string
  payload: OrderIntakePayload
  isDateNotAvailable?: boolean
  timeTrackingData?: TimeSelectorTrackingOrderEvent[]
}

export interface BusinessHours {
  cutOffTimeThreshold: string
  earliestPossible: string
  latestPossible: string
  minInterval: string
  minFixIntervalTime: string
  timeChangeInterval: string
  timeZone?: string
}

// Response Payload

export interface OrderRequestResponse {
  businessLine: string
  countryId: string
  createdBy: string
  createdOn: string
  customerNumber: string
  isSendingConfirmationEmailUnChecked: false
  modifiedOn: string
  orgUnitId: string
  requestData: RequestData
  requestId: string
  siteNumber: string
  status: OrderRequestStatus
  type: string
}

export interface OrderRequestGroupSuccessResponse {
  index: number
  result: OrderRequestResponse
  isSuccess: boolean
  delivery: OrderRequest
}

export interface OrderRequestGroupErrorResponse {
  index: number
  result: AxiosError
  isSuccess: boolean
  delivery: OrderRequest
}

export interface OrderRequestGroupResponse {
  index: number
  result: AxiosError | OrderRequestResponse
  isSuccess: boolean
  delivery: OrderRequest
}

export interface AdditionalDriverInfo {
  driverInstructions?: string
}

export interface HaulerInfo {
  truckLicensePlate?: string
  trailerLicensePlate?: string
  driverName?: string
  driverPhoneNumber?: string
}

interface RequestData {
  plant: Plant
  contact: Contact
  material: Material
  quantity: number
  quantityUom: string
  deliveryDate: string
  deliveryTime: DeliveryTime
  shippingType: ShippingType
  customerNumber: string
  shippingAddress: ShippingAddress
  customerReference: string
  additionalDriverInfo: AdditionalDriverInfo
  haulerInfo?: HaulerInfo
}

export interface OrderIntakeOption {
  shippingAddress: ShippingAddress
  contact: Contact
  materials: Dictionary<OrderIntakeMaterialOptionPayload[]>
  canDisableEmailSending: boolean
  driverInstructions?: string
}
export type OrderIntakeOptions = Dictionary<OrderIntakeOption>

export interface Contact {
  mainPhone?: string
  fullName?: string
  backupPhone?: string
  siteContactName?: string
  carbonCopyEmail?: string
}

export interface OrderCancellation {
  cancelReasonNotes: string
  orderItemPositionNumber: string
  orderItemNumber: string
  prefix: string
  businessLineCode: string | undefined
  checkSum: string | undefined
  timeZone: string
}

export interface TruckCapacity {
  capacity: number
  capacityUom: string
  maxCapacity: number
  minCapacity: number
  shortLoadThreshold: number
  quantityChangeIncrement?: number
  numberOfDecimals?: number
  isRoundToChangeIncrement?: boolean
}

export interface CapacityRequest {
  quantity: number
  quantityType: QuantityType
}

export type ConfigurableTimeSlot = {
  plantId: string
  date: string
  slotConfig: SlotConfiguration
  slotStatus: SlotStatus
}

export interface SlotConfiguration {
  id: string
  country: string
  slotName: string
  slotStart: string
  slotEnd: string
  isAlwaysFull: boolean
  slotPosition: number
}

export interface SlotStatus {
  isBlockingDelivery: boolean
}

export interface Plant {
  city: string
  country: string
  plantName: string
  plantNumber: string
  postalCode: string
  state: string
  street: string
  configurableSlots?: ConfigurableTimeSlot[]
}

export interface SalesOfficeData {
  country: string
  region: string
  businessLine: string
  nationalFlg: boolean
  salesOfficeEmail: string
  qcTechOfficeEmail: string
  salesOrganization: string
  companyName: string
  plant: string
  productCategoryCode: string
}

export interface OrderCancellationOptions {
  businessDays: OrderCancellationBusinessDay[]
  businessHoursOptions: BusinessHours
}

export interface CancellationRequestStatus {
  requestId: string
  status: string
}

export interface ShippingAddress {
  siteNumber: string
  siteName: string
  street: string
  postalCode: string
  city: string
  state: string
  country?: string
}

export interface Material {
  materialEnteredNumber: string
  materialNumber: string
  materialDescription: string
  businessLine: BusinessLineType
  quantityType: QuantityType
  contractItemPositionNumber: string
  contractNumber: string
}

// Date and time related
export interface CutOffTime {
  dates: string[]
  timestamp: string
}

export interface DateRangeExceptions {
  weekends?: string[]
  publicHolidays?: PublicHoliday[]
  excludedDates?: string[]
}

export interface BusinessDay {
  dayOfWeek: string
  leadTime: number
  cutOffTime?: CutOffTime
  cancellationCutOffTime?: string
}

export interface OrderCancellationBusinessDay {
  dayOfWeek: string
  leadTime: number
  cutOffTime?: string
  cancellationCutOffTime?: string
}

interface PublicHoliday {
  name: string
  date: string
}

export interface DateRange {
  from: string
  to: string
  fromAfterCutoff: string
  toAfterCutoff: string
  exceptions?: DateRangeExceptions
  businessDays?: BusinessDay[]
}

export interface DeliveryTime {
  earliest: string
  latest: string
}

export interface DefaultDeliveryWindow {
  defaultEarliestLoadTime: string
  defaultLatestLoadTime: string
}

// Order Form
export interface BulkCementOrderIntakeStateType {
  selectedSite?: OrderIntakeOption
  selectedSiteCollect?: OrderIntakeOption
  deliveries: OrderRequest[]
  collections: OrderRequest[]
  orderSummary: OrderRequestResponse[]
  isReset: boolean
}

export interface OrderRequests {
  isFetching: boolean
  error: Error | null
  orderRequests: OrderRequestResponse[]
}

export interface MaterialDelivery {
  id: string
  index: number
  dateAndTime: string
  materialDescription: string | null | undefined
  quantity: number
  plant: string | null | undefined
}

export interface GroupedMaterialDeliveries {
  headline: MaterialDeliveryDescription
  summaryItems: MaterialDelivery[]
}

export interface MaterialDeliveryDescription {
  number: string
  quantity: number
  capacityUom: string | undefined
  description: string | null | undefined
}

export interface GroupedPlacedDeliveries {
  headline: MaterialDeliveryDescription
  orders: OrderIntakePayload[]
  groupedOrderSummary: OrderRequestGroupResponse[]
}

export type ActiveMaterialDeliveryItem =
  | 'dateFilter'
  | 'timePicker'
  | 'haulerInfo'
  | 'poNumber'
  | 'details'
export type ActiveMaterialEditorMode = 'edit' | 'add'

export type TimeSelectorTrackingEventType =
  | '<earliest'
  | '>earliest'
  | '<latest'
  | '>latest'
  | 'earliestInputField'
  | 'latestInputField'

export type OptionSelectInputChangeType = 'decrease' | 'increase' | 'inputField'

export interface TimeSelectorTrackingOrderEvent {
  orderSessionEventId: string
  cardId: string
  slotManaged: boolean
  startEditingTimeStamp: string
  events: Dictionary<number>
  applyChangesTimeStamp: string
}

export interface RequestForQuoteResponse {
  requestId: string
  status: string
}

export interface AddingNewMaterialMetadata {
  isAddingNewMaterial: boolean
  newMaterialIndex: number
}

export interface LabelValueType {
  label: string
  value: string
}

export interface LabelValueCodeType {
  label: string
  value: string
  code: string
}
