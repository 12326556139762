import {Typography} from '@hconnect/uikit'
import {Box, Grid, styled} from '@mui/material'
import React from 'react'

import {BusinessLineTypes} from '../common/types'
import {ShippingType} from '../Pages/Order/Order.types'

export const Div = (props) => <Typography component="div" {...props} />

export const ValueDiv = styled(Div)({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '16px'
})

export interface FieldValueType {
  label: string
  value?: string | JSX.Element | null
  children?: any
  gutter?: boolean
  dataTestId?: string
  variant?: 'vertical' | 'horizontal'
  helpText?: string
  actionButton?: JSX.Element
  noLabel?: boolean
  breakable?: boolean
  borderLeftHtmlColor?: string
  subtitle?: string | null
  dataTestIdSubtitle?: string
}

interface SubTitleType {
  subtitle?: string | null
  dataTestIdSubtitle?: string
}

const SubTitle: React.FC<SubTitleType> = ({subtitle = null, dataTestIdSubtitle}) => {
  if (!subtitle || !subtitle.length) {
    return null
  }
  return (
    <Box ml={1.5} mt={0.75} data-test-id={dataTestIdSubtitle}>
      <Typography customVariant="formLabel" customColor="textSecondarySoft">
        {subtitle}
      </Typography>
    </Box>
  )
}

export const FieldValue: React.FC<FieldValueType> = ({
  label,
  value,
  children,
  gutter,
  dataTestId,
  variant = 'vertical',
  helpText,
  actionButton,
  noLabel = false,
  breakable,
  borderLeftHtmlColor,
  subtitle = null,
  dataTestIdSubtitle
}) => {
  return (
    <>
      <Box
        mb={gutter ? 2 : 0}
        pl={borderLeftHtmlColor ? 1 : 0}
        aria-label={label.includes('Order') ? label : `Order ${label}`}
        display="flex"
        flexDirection={variant === 'vertical' ? 'column' : 'row-reverse'}
        width={variant === 'vertical' ? 'auto' : 320}
        borderLeft={borderLeftHtmlColor ? `4px solid ${borderLeftHtmlColor}` : 'none'}
      >
        <Box
          flex={2}
          ml={variant === 'vertical' ? 0 : 3}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {!noLabel ? (
            <Typography
              variant="body2"
              customColor="textPrimarySoft"
              component="div"
              style={{textTransform: 'uppercase', fontWeight: 700, fontSize: 12}}
            >
              {label}
            </Typography>
          ) : null}
          {helpText ? <ValueDiv data-test-id={dataTestId}>{helpText}</ValueDiv> : null}
          {actionButton ? actionButton : null}
        </Box>
        {!!value || children ? (
          <Box flex={0}>
            <ValueDiv
              data-test-id={dataTestId}
              style={{...(breakable ? {overflowWrap: 'break-word'} : {})}}
            >
              {value || children}
            </ValueDiv>
          </Box>
        ) : null}
      </Box>
      <SubTitle subtitle={subtitle} dataTestIdSubtitle={dataTestIdSubtitle} />
    </>
  )
}

interface FieldDetailsType extends FieldValueType {
  businessLine?: BusinessLineTypes
  shippingType?: ShippingType
  showForBusinesslines?: BusinessLineTypes[]
  showForShippingType?: ShippingType
  fullWidth?: boolean
}

export const FieldDetails: React.FC<FieldDetailsType> = ({
  dataTestId,
  label,
  value,
  children,
  gutter,
  businessLine,
  shippingType,
  showForShippingType,
  showForBusinesslines,
  variant = 'vertical',
  helpText,
  actionButton,
  fullWidth
}) => {
  if (!value) {
    return null
  }
  if (
    showForBusinesslines &&
    showForBusinesslines.length &&
    businessLine &&
    !showForBusinesslines.includes(businessLine)
  ) {
    return null
  }
  if (shippingType && showForShippingType && showForShippingType !== shippingType) {
    return null
  }
  return (
    <Grid item {...(!fullWidth ? {xs: 6, md: 3} : {})}>
      <FieldValue
        dataTestId={dataTestId}
        label={label}
        value={value}
        gutter={gutter}
        variant={variant}
        helpText={helpText}
        actionButton={actionButton}
      >
        {children}
      </FieldValue>
    </Grid>
  )
}
